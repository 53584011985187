import React, {Component} from 'react';
//import { Link } from 'react-router-dom';
//import Session from '../Services/Session';
import  { ReactComponent as CategoriaIcon } from '../Layout/Assets/medalla.svg';
import Api from '../Services/Api';
import VoteEdit from './VoteEdit';

import Session from '../Services/Session';
//import Placeholder from '../Layout/Assets/placeholder.png';

export default class VotingProduct extends Component {
	constructor(props) {
		super(props);
		let id = this.props.match.params.id
		this.state = {
			id:id,
			data:null,
			showEdit:false,
			infoUser:Session.getInfoUser(),
		}
		this.shwoFrom = this.shwoFrom.bind(this)
		this.updateVoting = this.updateVoting.bind(this)
		this.deleteVoting = this.deleteVoting.bind(this)
	}
	componentDidMount() {
		window.scrollTo(0,0)
		this.loadVoting(this.state.id)
	}
	async loadVoting(id) {
		let response = await Api.getVoting(id)
		this.setState({
			data:response
		})
		
	}
	updateVoting(data) {
		this.setState({
			data:data
		})
	}
	shwoFrom() {
		this.setState({
			showEdit: !this.state.showEdit
		})
	}
	async deleteVoting() {
		let response = await Api.deleteVoting(this.state.id)
		if (response.success) {
			window.location.href = '/mi/perfil/'+this.state.data.voter_username;
		}
	}
	render() {
		const { data } = this.state
		return (
			<div className="row justify-content-md-center">
				<div className="col-12 col-lg-4 px-1">
				{ data !== null ?
					<>
					<div className="card">
						<img 
							src={data.photo_url === null ? data.product.logo_url :  data.photo_url }
							className="card-img-top" alt="..."/>
						<img 
						alt=""
						className="img-fluid"
						src={data.restaurant.logo_url}
						style={{
							"position": "absolute",
							"width": "65px",
							"height": "65px",
							"left": "7px",
							"top": "7px",
							"background":"white none repeat scroll 0% 0%",
							//"border": "1px solid  #dee2e6",
							"borderRadius": "50%",
							"padding": "5px",
						}}/>
						<div className="card-body">
							<h5 className="card-title text-danger">
								{data.product.name}
								<span className="show-ponits">
									<i
										className="fas fa-star text-warning"
									/> 
									<span className="ml-2 text-dark">{data.rating}</span>
								</span>
							</h5>
							
							{ 
								this.state.showEdit ? 
								<div className="row"><VoteEdit shwoFrom={this.shwoFrom} data={data} updateVoting={this.updateVoting}/></div> :
								<p className="card-text">
									<strong>Comentario</strong>
									{
										data.voter_username === this.state.infoUser.username ?
										<>
											<button class="btn btn-primary btn-sm rounded-pill px-2 pt-1 pb-0 float-right" type="button" onClick={this.shwoFrom}>
												<i class="fas fa-pencil-alt"></i>
											</button> 

											<button class="btn btn-danger btn-sm rounded-pill px-2 pt-1 pb-0 float-right mx-2" type="button" onClick={this.deleteVoting}>
												<i class="fas fa-trash-alt"></i>
											</button>
										</>: null
									}
									
									<hr className="my-1"/>
									{
										data.description === null ? 
										<h5 className="text-center py-5">Sin comentario</h5>
										: data.description
									}
								</p>
							}
						</div>
					</div>
					</>
					:
					<h5 className="text-center">
						cargando...
					</h5>
				}
				</div>
			</div>
		)
	}
}