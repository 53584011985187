import React, { Component, useEffect, useState } from 'react';
import { Col, Row, Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';
import Api from '../Services/Api';
import FormProcessing from '../Services/FormProcessing';


const FormProduct = (props) => {
	const { products, loadList } = props
	const [data, setData] = useState({});
	const [form, setForm] = useState({});

	useEffect(() => {
		if(products!=null){
			setData(products)
			setForm(products)
		}
	},[props.products]);
	const selectActive = { selected: 'none'}

	const handleOnChnage = async (e,info)  => {
		if(e.target && e.target.type === 'file' && typeof e.target.files[0] !== 'undefined'){
			const name = e.target.files[0].name
			const promise = await  FormProcessing.getBase64(e.target.files[0]);
			setForm({
				...data,
				...form,
				logo_product:{
					file: promise.result,
					name,
				},
			});
		}else{
			const name =  e.target ? e.target.name  : e;
			const value =  e.target ?
							 e.target.value  : info.value ? 
							 	info.value : info;
			setForm({
				...data,
				...form,
				[name]:value,
			});
		}
	}
	const handleSubmit = async (e)  => {
		e.preventDefault();
		let response = {};
		if (form.id) {
			delete form.selects
			response = await Api.adminProductsUpdate(form.id,form);
			alert('Actualizado de forma exitosa!');
		}else{
			delete form.selects
			response = await Api.adminProductsCreate(form);
			alert('Creado de forma exitosa!');
		}
		loadList()
	}
	return(
		<Form onSubmit={ (e) => handleSubmit(e) }>
			<Row form>
				<Col md={6}>
					<FormGroup>
						<Label for="productName">Nombre</Label>
						<Input required type="text" id="productName" name="name" placeholder="nombre del porveedor.." defaultValue={data.name} onChange={(e) => handleOnChnage(e)}/>
					</FormGroup>
				</Col>
				<Col md={6}>
					<FormGroup>
						<Label for="productSlug">Slug</Label>
						<Input required type="text" id="productSlug" name="slug" placeholder="nombre del evento.." defaultValue={data.slug} onChange={(e) => handleOnChnage(e)}/>
					</FormGroup>
				</Col>
			</Row>
			<Row form>
				<Col md={12}>
					<FormGroup>
						<Label for="productsCategoryId">Categoría</Label>
						<Input 
							type="select" id="productsCategoryId" name="category_id" 
							value={form.category_id} 
							onChange={(e) => handleOnChnage(e)}
						>
							<option value=''>Selecciona tu poveedor</option>
							{data.selects ? data.selects.categories.map(function(elem, index) {
							return(
								<option value={elem.id} key={`category_id-${index}`}>
								{elem.name}
								</option>
							);
						}) : <option>Sin Provedores</option>}
						</Input>
					</FormGroup>
				</Col>
				<Col md={6}>
					<FormGroup>
						<Label for="productsProvaiders">Proveedores</Label>
						<Input 
							required type="select" id="productsProvaiders" name="provaider_id" 
							value={form.provaider_id} 
							onChange={(e) => handleOnChnage(e)}
						>
							<option value=''>Selecciona tu poveedor</option>
							{data.selects ? data.selects.providers.map(function(elem, index) {
							return(
								<option value={elem.id} key={`providers-${index}`}>
								{elem.name}
								</option>
							);
						}) : <option>Sin Provedores</option>}
						</Input>
					</FormGroup>
				</Col>
				<Col md={6}>
					<FormGroup>
						<Label for="productsEnvent">Evento</Label>
						<Input 
							required type="select" id="productsEnvent" name="event_id" 
							value={form.event_id} 
							onChange={(e) => handleOnChnage(e)}
						>
							<option value=''>Selecciona tu evento</option>
							{data.selects ? data.selects.events.map(function(elem, index) {
								return(
									<option value={elem.id} key={`events-${index}`}>
									{elem.name}
									</option>
								);
							}) : <option>Sin Eventos</option>}
						</Input>
					</FormGroup>
				</Col>
			</Row>
			<Row form>
				<Col md={6}>
					<Label for="productsFile">Foto</Label>
					<FormGroup>
						<Input type="file"  id="productsFile" name="logo_product" onChange={(e) => handleOnChnage(e)} />
						<FormText color="muted">
							Recuerda subir las fotos con las medidas necesarias.
						</FormText>
					</FormGroup>
				</Col>
				<Col md={6}>
					{
						!form.logo_product && data.logo_url  ?
						<img src="img-fluid" src={data.logo_url} height="80"/> : 
						(form.logo_product ? <img src="img-fluid" src={form.logo_product.file} height="80"/> : '')
					}
				</Col>
			</Row>
			
			<FormGroup>
				<Label for="productsDescripcion">Descripción(Acepta tags html)</Label>
				<Input type="textarea" id="productsDescripcion" name="description" defaultValue={data.description} onChange={(e) => handleOnChnage(e)} rows='8' cols='50'/>
			</FormGroup>
			<Button>{data.id ? 'Actualizar' : 'Crear'}</Button>
		</Form>
	);
}
export default  FormProduct;