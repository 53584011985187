import React, { useState } from 'react';
import { Col, Row, Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';
import TableGeneral from '../Layout/Components/TableGeneral';
import Papa from 'papaparse'

const UploadCsv = () => {
	const [csvData, setCsvData] = useState(`
		'Chrysler Imperial','14.7','8','440','230','3.23','5.345','17.42','0','0','3','4'
		'Fiat 128','32.4','4','78.7','66','4.08','2.2','19.47','1','1','4','1'
	`);

	const handleChange = (e) => {
		Papa.parse(e.target.files[0], {
			complete: function(results) {
				setCsvData(results)
				console.log('value csv',results);
			}
		});
	}
	return(
		<Form>
			<FormGroup>
				<Label for="exampleFile">Foto</Label>
				<Input type="file" name="file" id="exampleFile" onChange={(e) => handleChange(e)}/>
				<FormText color="muted">
				Sube el csv de los productos.
				</FormText>
			</FormGroup>
			<TableGeneral
				rowsWithColumns={csvData[0]}
				rowHeader={csvData[0]}
				tableClassName={"table table-bordered table-striped table-hover table-responsive small"}
				tableRowClassName={""}
				tableColumnClassName={"py-1 px-0 small"}
			/>
			<Button>Submit</Button>
		</Form>
	);
}
export default  UploadCsv;