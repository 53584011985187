import React, {Component} from 'react';
import Session from '../Services/Session';
import Api from '../Services/Api';
import  InputFileImg from '../Layout/Components/InputFileImg'
import { Spinner } from 'reactstrap'
import FormProcessing from '../Services/FormProcessing'
import { DateTime } from 'react-datetime-bootstrap';
import UserImg from '../Layout/Assets/user.png';
import Avatars from '../VoteHere/Avatars';
import {AvatarsList} from '../VoteHere/Avatars';

export default class FormEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			resetForm:false,
			userData:null,
			userName:Session.getUserName(),
			private:false
		};
		this.form = React.createRef()
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleAvatar = this.handleAvatar.bind(this);
	}
	componentDidMount(propsDev) {
		let p = this.props
		if(this.state.userData===null){
			this.loadUser(this.state.userName)
		}
	}
	async loadUser(userName){
		let data = await Api.showUser(userName)
		if(data.warning){
			alert('usuario no encontrado!')
		}else{
			this.setState({
				userData:data,
				resetForm:!this.state.resetForm
			})
		}
	}
	async handleAvatar(data){
		let response = await Api.updateAvatar(this.state.userName,{avatar_selected:data})
		this.loadUser(this.state.userName)
	}
	async handleSubmit(e,userName){
		e.preventDefault();
		
		this.setState({
			loading:true,
		})
		let data = await FormProcessing.toObject(this.form.current)
		let response = await Api.updateUser(userName,data)
		

		let validate = []
		if(response.enable_validations===true){
			validate = response
			this.setState({
				loading:false,
			})
		}else{
			if (typeof response.username !== 'undefined') {
				Session.edit(response)
				this.setState({
					loading:false,
					userData:response,
					resetForm:!this.state.resetForm
				})
			}
		}
		FormProcessing.validate(this.form.current,validate)
	}
	handleInputChange(event) {
		const target = event.target;
		const value = target.value === 'private' ? target.checked : target.checked;
		const name = target.name;
		let userData = this.state.userData;
		userData[name]=value;
		console.log(userData);
		this.setState({
		  userData
		});
  	}
	render() {
		const {resetForm, userData, loading, userName} = this.state
		return(
			<form className="row" onSubmit={(e)=>this.handleSubmit(e,userName)} ref={this.form}>
				<div className="bg-light col py-5 text-center mt-negative-4">
					<h3 className="mt-4">Editar mi perfil</h3>
					<InputFileImg 
					name="avatar"
					reset={resetForm}
					srcInput={
						(userData ? ( 
							userData.avatar_selected ==null ? (userData.avatar_url == null ? UserImg : userData.avatar_url) : AvatarsList[userData.avatar_selected]
						) : null)
					}
					nameFile={(userData ? 'Avatar de usuario' : null)}
					className="input-file-radius"
					/>
					<Avatars data={{handleAvatar:this.handleAvatar}}/>
					<div className="form-group col px-0">
						<input 
							type="text"
							className="form-control py-4"
							name="username"
							aria-describedby="username"
							placeholder="Nombre usuario"
							defaultValue={ userData ? userData.username : '' } 
						/>
					</div>
					<div className="form-group col px-0">
						<input 
							type="text"
							className="form-control py-4"
							name="name"
							aria-describedby="name"
							placeholder="Nombre"
							defaultValue={ userData ? userData.name : '' } 
						/>
					</div>
					<div className="form-group col px-0">
						<input 
							type="text"
							className="form-control py-4"
							name="surname"
							aria-describedby="surname"
							placeholder="Apellido"
							defaultValue={ userData ? userData.surname : '' } 
						/>
					</div>
					<div className="form-group col px-0">
						<input 
							type="email"
							className="form-control py-4"
							name="email"
							aria-describedby="email"
							placeholder="Correo"
							defaultValue={ userData ? userData.email : '' } 
						/>
					</div>
					<div className="form-group col px-0">
						<input 
							type="text"
							className="form-control py-4"
							name="instagram"
							aria-describedby="instagram"
							placeholder="Cuenta instagram"
							defaultValue={ userData ? userData.instagram : '' } 
						/>
					</div>
					<p className="col">
						Tu cuenta de instagram es para vincular las stories de los eventos 
						<i className="fa fa-info-circle text-danger"/>
					</p>
					<div className="form-group col px-0">
						<DateTime 
							name="birth_date"
							pickerOptions={{
								format:"YYYY-MM-DD",
							}} 
							placeholder="Fecha de Nacimento"
							aria-describedby="birthDate"
							value={ userData ? userData.birth_date : '' }
						/>
					</div>
					{/*<div className="input-group mb-3 col px-0">
						<select
							className="custom-select ml-0"
							aria-label="Paises"
						>
							<option>Chile</option>
						</select>
						<select
							className="custom-select mr-0"
							name="comuna_id"
							aria-label="Region de Chile"
						>
							<option value="">Región/Comuna</option>
							<option value="">none</option>
						</select>
					</div>*/}
					<button
						type="submit"
						className="btn custom-btn-red px-5 py-3"
						disabled={this.state.loading ? true : false}
					>
						{ 
							this.state.loading === false ? 'GUARDAR' :
							<span>
							<Spinner style={{ 
								width: '1rem',
								height: '1rem',
								top: '-4px',
								position: 'relative'
							}}/> GUARANDO..
							</span>

						}
					</button>
					<div className="form-check pb-3 px-3 mt-4">
						<input
							
							type="checkbox"
							name="private"
							id="private1"
							defaultValue="1"
							checked={ userData ? userData.private : this.state.private }
							onChange={this.handleInputChange}
						/>
						<br/>
						<label className="form-check-label" htmlFor="exampleRadios1">
							DESEO QUE MI PERFIL SEA PRIVADO
						</label>
					</div>
				</div>
			</form>
		)
	}
}