import React, {Component} from 'react';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import Session from '../Services/Session';
import Api from '../Services/Api';
import chop from '../Layout/Assets/chop.gif';
import  { ReactComponent as Start } from  '../Layout/Assets/boton_estrella-01.svg'
import Paginate from '../Layout/Components/Paginate'
import UserImg from '../Layout/Assets/user.png'

export default class List extends Component {
	constructor(props) {
		super(props);
		let querySearch = queryString.parse(props.location.search)
		let name = typeof querySearch.n === 'undefined' ? null : querySearch.n;
		let username = this.props.match.params.user_name
		let typeSearch = typeof props.typeSearch  === 'undefined' ? null : props.typeSearch ;
		console.log(typeSearch)
		this.state = {
			name:name,
			typeSearch,
			username,
			users:false,
			pagination:null,
			loading:true,
			loadingFollowUser:false,
			loadingStopFollowing:false,
		};
		this.loadList = this.loadList.bind(this)
	}
	componentDidMount() {
		window.scrollTo(0,0)
		this.loadList({})
	}
	componentDidUpdate(prevProps){
		// const {user_name} = this.props.match.params
		// if (user_name !== prevProps.match.params.user_name) {
		// 	this.setState({
		// 		infoLoad:0,
		// 		notFound:0,
		// 	})
		// 	this.loadProfile(user_name)
		// }
	}
	async loadList(page={}){
		page.name=this.state.name

		let typeSearch=this.state.typeSearch;
		console.log(typeSearch);
		let response={}
		if(typeSearch===null && Session.isAuth()== true){
			response = await Api.listUsersPrivate(page)
		}
		if(typeSearch===null && Session.isAuth()== false){
			response = await Api.listUsers(page)
		}
		let base = Session.isAuth() ? '/' : '/public/';
		if(typeSearch==='followed'){
			response = await Api.followUserList(base+typeSearch+'/for/'+this.state.username)
		}
		if(typeSearch==='followers'){
			response = await Api.followUserList(base+typeSearch+'/of/'+this.state.username)
		}

		
		var pages = {
			last_page:response.last_page,
			current_page:response.current_page,
			per_page:response.per_page
		}
		this.setState({
			users:response.data,
			pagination:pages,
			loading:false
		})
	}
	async followUser(e,user,page){
		this.setState({
			loadingFollowUser:user.id,
		})
		let response = await Api.followUser(user.username)
		if(typeof response.error!=='undefined'){
			this.setState({
				loadingFollowUser:false,
			})
		}else{
			this.setState({
				loadingFollowUser:false,
				count_followers:response.count_followers,
			})
		}
		this.loadList(page)
	}
	async stopFollowing(e,user,page){
		this.setState({
			loadingStopFollowing:user.id
		})
		let response = await Api.stopFollowing(user.username)
		if(typeof response.error!=='undefined'){
			this.setState({
				loadingStopFollowing:false
			})
		}else{
			this.setState({
				loadingStopFollowing:false,
			})
		}
		this.loadList(page)
	}
	render() {
		let {
			users,
			pagination,
			loading,
			name,
			loadingFollowUser,
			loadingStopFollowing,
			typeSearch,
			username
		} = this.state

		const HerderList = () => {
			//Si el tipo es null entonces es una busqueda general
			if(typeSearch===null){
				return (
					<h3 className="mt-5">
						Resultados para:
						<br/>
						<small className="text-primary">
							{name}
						</small>
					</h3>
				);
			}
			if(typeSearch==='followed'){
				return (
				<h5 className="mt-5 mb-0">
					Seguidos por {username}
				</h5>
				)
			}
			if(typeSearch==='followers'){
				return (
				<h5 className="mt-5 mb-0">
					Seguidores de {username}
				</h5>
				)
			}
		}
		return(
			<div className="row">
				<div className="bg-light col py-5 text-center mt-negative-4">
					<HerderList/>
				</div>
				<div className="col-12">
				<div className="row justify-content-center d-flex align-items-stretch">
					{
						typeof users === 'object' ?
							users.map(function(user, i){
								return (
									<div className="px-1 col-12 col-md-6 col-lg-3 my-1" key={"r-"+i}>								
										<div className="border mb-2 text-dark h-100 text-center px-3">
											<div className="row align-items-center">
												<div className="col-3 px-1">
													<img alt="" className="img-fluid w-100 w-md-75 py-2" src={ user.avatar_url == null ? UserImg : user.avatar_url }/>
												</div>
												<div className="col-6 text-left">
													<p className={"font-weight-bold mb-0 res-id-"+user.id}>
													<small>
														{
															user.full_name.length === 1 ?
															user.username :
															user.full_name
														}
													</small>
													<br/>
													<small className="text-muted">
													{user.count_voting} votaciones
													</small>
													<br/>
													<Link to={"/perfil/"+user.username}>
														ver perfil
													</Link>
													</p>
												</div>
												{ Session.isAuth() ? 
													<div className="col-3">
														{ user.is_followed === 1 ?
															<button
																className="btn btn-sm btn-outline-secondary"
																onClick={
																	(e) => this.stopFollowing(e,user,{
																		page:pagination.current_page,
																		name
																	})
																}
																disabled={loadingStopFollowing===user.id}
															>
																eliminar
															</button>
														:
															<button
																className="btn btn-sm custom-btn-red"
																onClick={(e) => this.followUser(e,user,{
																		page:pagination.current_page,
																		name
																	})
																}
																disabled={loadingFollowUser===user.id}
															>
																Seguir
															</button>
														}
													</div>
													: null
												}

											</div>
										</div>
									</div>
								)
							},this,pagination,name)
						:
							<>
							{	loading===true ?
								<div className="col-md-4 text-center h4">
									{/* <img alt="" className="img-fluid" src={chop}/> */}
									<Start height="35px" className="start-vote"/>
									<br/>
									Buscando participantes.
								</div>
								: 'No se encontraron coincidencias!'
							}
							</>
					}
					<div
						className="col-12 d-flex justify-content-center fixed-bottom pb-5"
						style={{
							marginBottom: '5rem',
						}}
					>
						{
							pagination != null ?
							<Paginate 
								last_page={pagination.last_page}
								current_page={pagination.current_page} 
								per_page={pagination.per_page}
								loadList={this.loadList}
								classNamew="border border-bottom-0 bg-white px-5 pt-4"
							/>
							:null
						}
					</div>
				</div>
				</div>
			</div>
		)
	} 
}