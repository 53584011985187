import React, { useEffect, useState } from 'react';
import { Button, Rate } from 'rsuite';
import Icons from '../../Components/Icons'
import { Link } from 'react-router-dom';
import {GetNumericFloat} from '../../Services/Helpers';
import Slider from "react-slick";
import { SampleNextArrow,SamplePrevArrow }from './WidgetCommunity'

import Api from '../../Services/Api';

const WidgetReviews  = (props) => {
  const [ list, setList ] = useState([]);
  useEffect(()=>{
    const run = async () => {
      let response = await Api.getVotingListing({lot:20});
      setList(response.data);
    }
    run()
  },[]);
  const settings1 = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 6000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 2000, // xxx-large
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: true,
          dots: true,
          initialSlide: 5,
        }
      },
      {
        breakpoint: 720, // medium
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 576, // small
        settings: {
          className: "center",
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "80px",
        }
      },
    ]
  }
  return(
    <div className="row pt-4 pb-5 bg-light">
      <div className="col-12">
        <h4 className="mb-4">
          <Link to="/reviews" className="h4">
            Reviews 
          </Link>
          <Link to="/reviews" className="float-right">
            <i class="fas fa-arrow-right"/>
          </Link>
        </h4>
        <Slider {...settings1}>
          {
            list.map((item,i)=>{
              return(
                <div className="col-12 px-1" key={`slick-item-${i}`}>
                {/* <div className="col-6 col-md-3 col-lg-2 px-1"> */}
                <div className="card w-100 my-1">
                  <img alt="" className="card-img-top" src={item.photo_url} style={{
                    maxHeight: "150px",
                    objectFit: "cover",
                    width: "100%",
                  }}/>
                  <div className="card-body">
                    <p className="small font-weight-bolder"
                       style={{
                        minHeight: "15px",
                        display: '-webkit-box',
                        overflow: 'hidden',
                        webkitLineClamp: '1',
                        webkitBoxOrient: 'vertical',
                      }}
                    >{item.product.name}</p>
                    <Rate value={item.rating} allowHalf readOnly size="xs"/>{' '}
                    <small className="small rate-item">{GetNumericFloat(item.rating)}</small>
                    <hr className="row my-1"/>
                    <strong className="small">Comentario</strong>
                    <p className="card-text small"
                      style={{
                        minHeight: "58px",
                        display: '-webkit-box',
                        overflow: 'hidden',
                        webkitLineClamp: '4',
                        webkitBoxOrient: 'vertical',
                      }}
                    >
                      {item.description}
                    </p>
                    <Link to={`/juez/votacion/${item.id}`} className="btn btn-link p-0">ver más</Link>
                  </div>
                </div>
                </div>
              )
            })
          }
        </Slider>
      </div>
    </div>
  );
}

export default WidgetReviews;