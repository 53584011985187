import React, {Component} from 'react';

import FormProcessing from '../Services/FormProcessing'
import Session from '../Services/Session';
import Api from '../Services/Api';

import ModalGeneral from '../Layout/Components/ModalGeneral';
import InputFileImg from '../Layout/Components/InputFileImg'
import Paginate from '../Layout/Components/Paginate'
import UserImg from '../Layout/Assets/user.png';

import { DateTime } from 'react-datetime-bootstrap';
import { Spinner } from 'reactstrap'

import FormEvents from './FormEvents';
import UploadCsv from './UploadCsv';

export default class Events extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal:{
				toggle:null,
				body:null,
				title:null,
			},
			pagination:null,
			events:[]
		};
		this.handleToggleModal = this.handleToggleModal.bind(this);
		this.eventsDetail = this.eventsDetail.bind(this);
		this.eventsDelete = this.eventsDelete.bind(this);
	}
	UNSAFE_componentWillMount() {
		window.scrollTo(0,0)
		this.eventsDetail()
	}
	handleToggleModal(type,data=null){
		const { modal } = this.state
		modal.toggle=Math.random();
		switch (type) {
			case "newEvent":
				modal.title='Nuevo Evento';
				modal.body=<FormEvents event={data} loadList={this.eventsDetail}/>;
				break;
			case "editEvent":
				modal.title=`Evento: ${data.name}`;
				modal.body=<FormEvents event={data} loadList={this.eventsDetail}/>;
				break;
			case "uploadCsv":
				modal.title='Subir csv';
				modal.body=<UploadCsv/>;
				break;
			default:
				// code...
				break;
		}
		this.setState({
			modal
		});
	}
	async eventsDetail(page={}){
		let response = await Api.adminEvents(page)
		const pagination = {
			last_page:response.last_page,
			current_page:response.current_page,
			per_page:response.per_page
		}
		this.setState({
			events:response.data,
			pagination,
			modal:{
				toggle:false
			}
		});
	}
	async eventsDelete(id){
		let response = await Api.adminEventsDelete(id)
		alert(response.message)
		this.eventsDetail()
	}
	render() {
		const { counters, last, events, modal, pagination} = this.state
		return(
			<div className="row">
				<ModalGeneral className="" size="lg" title={modal.title} run={modal.toggle}>
					{modal.body}
				</ModalGeneral>
				<div className="col-9">
					<h2 className="mt-3">Eventos</h2>
				</div>
				<div className="col-3 pt-3 text-right">
					<button className="btn btn-primary mr-3" type="button" onClick={() => this.handleToggleModal('newEvent')}>Agregar</button>
				</div>
				<div className="col-12">
					<hr/>
					<table className="table table-bordered table-hover">
						<thead>
							<tr>
								<th scope="col">#</th>
								<th scope="col">País</th>
								<th scope="col">Nombre</th>
								<th scope="col">inicio</th>
								<th scope="col">Fin</th>
								<th scope="col">Opciones</th>
							</tr>
						</thead>
						<tbody>
							{	events.length > 0 ? 
								events.map(function callback(event, index, array) {
								    return(
										<tr  valign="middle" key={`tr-event-${index}`}>
											<th scope="row">{event.id}</th>
											<th scope="row">{event.country_id}</th>
											<td>
												{event.name}
											</td>
											<td>{event.start}</td>
											<td>{event.end}</td>
											<td>{event.active === 1 ? <span className="badge badge-success" style={{fontWeight: "100",fontSize: "12px"}}>encurso</span> : <span className="badge badge-danger" style={{fontWeight: "100",fontSize: "12px"}}>deteniada</span>}</td>
											
											<td className="text-center">
												<button className="btn btn-primary mr-3" type="button" onClick={() => this.handleToggleModal('editEvent',event)}>Editar</button>
												<button className="btn btn-danger mr-3" type="button" onClick={() => this.eventsDelete(event.id)}>Elminar</button>
											</td>
										</tr>
								   	)
								},this)
								:
								<tr>
									<th scope="row" colspan="6">Sin eventos</th>
								</tr>
							}
						</tbody>
					</table>
				</div>
				<div className="col-12 d-flex justify-content-center fixed-bottom pb-5"
						style={{
							marginBottom: '5rem',
						}}>
					{
						pagination != null ?
						<Paginate 
							last_page={pagination.last_page}
							current_page={pagination.current_page} 
							per_page={pagination.per_page}
							loadList={this.eventsDetail}
							classNamew="border border-bottom-0 bg-white px-5 pt-4"
						/>
						:null
					}
				</div>
			</div>
		);
	}
}