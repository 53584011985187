import  React, { Component, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

//import the_top_sushi_blanco from '../Layout/Assets/the_top_sushi_blanco-1024x271.png';
//import placeholder_restaurant from '../Layout/Assets/placeholder-restaurant.jpg';

import chop from '../Layout/Assets/chop.gif';
import  { ReactComponent as Start } from  '../Layout/Assets/boton_estrella-01.svg'
import Paginate from '../Layout/Components/Paginate'
import Api from '../Services/Api'
import { GetForCountry }from '../Services/Helpers';
import InfiniteScroll from "react-infinite-scroll-component";
import Session from '../Services/Session';

const RestaurantList = (props) => {

  let history = useHistory();
  const [ globalState, setGlobalSatate ] = useState({
    zona: props.match.params.zona,
    restaurants: [],
    pagination:null,
    loading:true,
    hasMore:true
  })

  useEffect(()=>{
    localStorage.removeItem("last_vote_calificacion");
    localStorage.removeItem("last_vote_description");
    localStorage.removeItem("last_vote_image");
    localStorage.removeItem("last_vote_image_name");
    localStorage.removeItem("last_vote_image_title");
    localStorage.removeItem("last_vote_image_value");
    localStorage.removeItem("last_vote_numero_de_boleta");
    loadList()
  },[]);

  const fetchMoreData = (nex=1) => {
    if (globalState.pagination.current_page >= globalState.pagination.last_page) {
      setGlobalSatate({
        ...globalState,
        hasMore: false
      });
      return;
    }
    // a fake async api call like which sends
    // 20 more records in .5 secs
    setTimeout(() => {
      loadList({page:globalState.pagination.current_page+1})
    }, 500);
  };

  const  loadList = async (page={}) => {
		page.zona=globalState.zona;
		let response = await Api.restaurants(page)
		var pages = {
			last_page:response.last_page,
			current_page:response.current_page,
			per_page:response.per_page,
			total:response.total
		}
		const data = 
		setGlobalSatate({
      		...globalState,
			restaurants: response.data ? globalState.restaurants.concat(response.data) : globalState.restaurants,
			pagination:pages,
			loading:false
		})
	}

  let {restaurants, pagination, loading} = globalState
		const {country} = GetForCountry();
		let zonaTitle='';
		switch (globalState.zona) {
			case 'ZN':
			zonaTitle= <h5>Zona Norte</h5>
			break;
			case 'ZS':
			zonaTitle = <h5>Zona Sur</h5>
			break;
			case 'MT':
			zonaTitle = <h5>Zona Metropolitana</h5>
			//Declaraciones ejecutadas cuando el resultado de expresión coincide con valorN
			break;
			case 'lima':
			zonaTitle = <h5>Zona Lima</h5>
			//Declaraciones ejecutadas cuando el resultado de expresión coincide con valorN
			break;
			default:
			zonaTitle = <h5>{globalState.zona}</h5>
			break;
		}
		const link=window.location.pathname.substring(1).split('/')
console.log('restaurants',restaurants)
  return(
    <>
          <Link to={"/the-top/zona/"+link[2]} className="btn-right-top pulse" style={{
            position: 'fixed',
            right: '0px',
            zIndex: 1,
          }}>
						Puntuaciones
					</Link>
				<h1 className="text-center py-3" style={{textTransform: 'uppercase'}}>{zonaTitle}</h1>
				{/*<div className="d-block text-center b-gray">	
					<img alt="" className="img-fluid invertir-blancos" src={the_top_sushi_blanco} width="500"/>
				</div>*/}
        <InfiniteScroll
          dataLength={restaurants.length ? restaurants.length : 0 }
          next={fetchMoreData}
          hasMore={globalState.hasMore}
          loader={<p style={{ textAlign: "center" }} className="col-12 py-5">
            <b></b>
          </p>}
          className="row justify-content-center d-flex align-items-stretch"
          endMessage={
            <p style={{ textAlign: "center" }} className="col-12 py-5">
              <b>No hay más resultados</b>
            </p>
          }
        >
					{
						typeof restaurants === 'object' && restaurants.length > 0 ?
							restaurants.map(function(restaurant, i){
								return (
									<div className="px-1 col-4 col-md-4 col-lg-3 my-1" key={"r-"+i}>
										<Link to={Session.isAuth()?"/restaurante/" + restaurant.slug : "/sign/in" + "?return_url="+"/restaurante/" + restaurant.slug} className="h-100">									
											<div className="border mb-2 text-dark h-100 text-center px-3">
												<img alt="" className="img-fluid w-100 w-md-75 py-2" src={restaurant.logo_url}/>
												<button className="btn btn-danger mb-2 btn-sm">
													VOTA ACÁ
												</button>
												<p className={"font-weight-bold res-id-"+restaurant.id}>
												<small>{restaurant.name}</small>
												</p>
												{/*<p className="px-4 text-center">
													<small>{restaurant.address}, &nbsp;
													{restaurant.commune.name}</small>
												</p>*/}
											</div>
										</Link>
									</div>
								)
							})
						:
							<>
							{	loading===true ?
								<div className="col-md-4 text-center h4">
									<Start height="35px" className="start-vote"/>
									<br/>
									Buscando participantes..
								</div>
								: <h3>Sin Restoranes para {country}</h3>
							}
							</>
					}
        </InfiniteScroll>
					<div
						className="col-12 d-flex justify-content-center fixed-bottom pb-5"
						style={{
							marginBottom: '5rem',
						}}
					>
						{ /*
							pagination != null ?
							<Paginate 
								last_page={pagination.last_page}
								current_page={pagination.current_page} 
								per_page={pagination.per_page}
								loadList={loadList}
								classNamew="border border-bottom-0 bg-white px-5 pt-4"
							/>
							:null
            */}
					</div>
				

			</>
  );
}

export default RestaurantList;

export class RestaurantList2 extends Component {
	constructor(props) {
		super(props)
		this.state = {
			zona: this.props.match.params.zona,
			restaurants: false,
			pagination:null,
			loading:true,
		}
		this.loadList = this.loadList.bind(this)
	}
	componentDidMount() {
		window.scrollTo(0,0)
		this.loadList()
	}
	async loadList(page={}){
		page.zona=this.state.zona;
		let response = await Api.restaurants(page)
		var pages = {
			last_page:response.last_page,
			current_page:response.current_page,
			per_page:response.per_page
		}
		this.setState({
			restaurants:response.data,
			pagination:pages,
			loading:false
		})
	}
	render(){
		let {restaurants, pagination, loading} = this.state
		const {country} = GetForCountry()
		let zonaTitle = '';
		switch (this.state.zona) {
			case 'ZN':
			zonaTitle= <h5>Zona Norte</h5>
			break;
			case 'ZS':
			zonaTitle = <h5>Zona Sur</h5>
			break;
			case 'MT':
			zonaTitle = <h5>Zona Metropolitana</h5>
			//Declaraciones ejecutadas cuando el resultado de expresión coincide con valorN
			break;
			case 'lima':
			zonaTitle = <h5>Zona Lima</h5>
			//Declaraciones ejecutadas cuando el resultado de expresión coincide con valorN
			break;
			default:
			zonaTitle = <h5>{this.state.zona}</h5>
			break;
		}
		const link=window.location.pathname.substring(1).split('/')
		return (
			<>
          <Link to={"/the-top/zona/"+link[2]} className="btn-right-top pulse" style={{
            position: 'fixed',
            right: '0px',
            zIndex: 1,
          }}>
						Puntuaciones
					</Link>
				<h1 className="text-center py-3" style={{textTransform: 'uppercase'}}>{zonaTitle}</h1>
				{/*<div className="d-block text-center b-gray">	
					<img alt="" className="img-fluid invertir-blancos" src={the_top_sushi_blanco} width="500"/>
				</div>*/}
				<div className="row justify-content-center d-flex align-items-stretch">
					{
						typeof restaurants === 'object' ?
							restaurants.map(function(restaurant, i){
								return (
									<div className="px-1 col-4 col-md-4 col-lg-3 my-1" key={"r-"+i}>
										<Link to={"/restaurante/"+restaurant.slug} className="h-100">									
											<div className="border mb-2 text-dark h-100 text-center px-3">
												<img alt="" className="img-fluid w-100 w-md-75 py-2" src={restaurant.logo_url}/>
												<button className="btn btn-danger mb-2 btn-sm">
													VOTA ACÁ
												</button>
												<p className={"font-weight-bold res-id-"+restaurant.id}>
												<small>{restaurant.name}</small>
												</p>
												{/*<p className="px-4 text-center">
													<small>{restaurant.address}, &nbsp;
													{restaurant.commune.name}</small>
												</p>*/}
											</div>
										</Link>
									</div>
								)
							})
						:
							<>
							{	loading===true ?
								<div className="col-md-4 text-center h4">
									<Start height="35px" className="start-vote"/>
									<br/>
									Buscando participantes..
								</div>
								: <h3>Sin Restoranes para {country}</h3>
							}
							</>
					}
					<div
						className="col-12 d-flex justify-content-center fixed-bottom pb-5"
						style={{
							marginBottom: '5rem',
						}}
					>
						{
							pagination != null ?
							<Paginate 
								last_page={pagination.last_page}
								current_page={pagination.current_page} 
								per_page={pagination.per_page}
								loadList={this.loadList}
								classNamew="border border-bottom-0 bg-white px-5 pt-4"
							/>
							:null
						}
					</div>
				</div>
				

			</>
		);
	}
}