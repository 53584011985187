
import React, {Component} from 'react';
import Session from '../Services/Session';
import Api from '../Services/Api';

import ModalGeneral from '../Layout/Components/ModalGeneral';
import  InputFileImg from '../Layout/Components/InputFileImg'
import Paginate from '../Layout/Components/Paginate'
import UserImg from '../Layout/Assets/user.png';

import { Spinner } from 'reactstrap'
import FormProcessing from '../Services/FormProcessing'
import { DateTime } from 'react-datetime-bootstrap';
import FormProvaider from './FormProvaider';
import UploadCsv from './UploadCsv';


export default class Providers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal:{
				toggle:null,
				body:null,
				title:null,
			},
			pagination:null,
			provaiders:[]
		};
		this.handleToggleModal = this.handleToggleModal.bind(this);
		this.provaidersDetail = this.provaidersDetail.bind(this);
		this.provaidersDelete = this.provaidersDelete.bind(this);
	}
	UNSAFE_componentWillMount() {
		window.scrollTo(0,0)
		this.provaidersDetail()
	}
	async handleToggleModal(type,data={}){
		const { modal } = this.state
		modal.toggle=Math.random();
		let response = await Api.adminSelects({})
		data.selects=response;
		switch (type) {
			case "newProvider":
				modal.title='Nuevo proveedor';
				modal.body=<FormProvaider provaider={data} loadList={this.provaidersDetail}/>;
				break;
			case "editProvaider":
				modal.title=`Proveedor: ${data.name}`;
				modal.body=<FormProvaider provaider={data} loadList={this.provaidersDetail}/>;
				break;
			default:
				// code...
				break;
		}
		this.setState({
			modal
		});
	}
	async provaidersDetail(page={}){
		let response = await Api.adminProvaiders(page)
		const pagination = {
			last_page:response.last_page,
			current_page:response.current_page,
			per_page:response.per_page
		}
		this.setState({
			provaiders:response.data,
			pagination,
			modal:{
				toggle:false
			}
		});
	}
	async provaidersDelete(id){
		let response = await Api.adminProvaidersDelete(id)
		alert(response.message)
		this.provaidersDetail()
	}
	render() {
		const { counters, last, provaiders, modal, pagination} = this.state
		return(
			<div className="row">
				<ModalGeneral className="" size="lg" title={modal.title} run={modal.toggle}>
					{modal.body}
				</ModalGeneral>
				<div className="col-9">
					<h2 className="mt-3">Provedores</h2>
				</div>
				<div className="col-3 pt-3 text-right">
					<button className="btn btn-primary" type="button" onClick={() => this.handleToggleModal('newProvider')}>Agregar</button>
				</div>
				<div className="col-12">
					<hr/>
					<table className="table table-bordered table-hover">
						<thead>
							<tr>
								<th scope="col">#</th>
								<th scope="col">Imagen</th>
								<th scope="col">Nombre</th>
								<th scope="col">Descripción</th>
								<th scope="col">F. Creacion</th>
								<th scope="col" width="180">Opciones</th>
							</tr>
						</thead>
						<tbody>
							{	provaiders.length > 0 ? 
								provaiders.map(function callback(provaider, index, array) {
								    return(
										<tr  valign="middle"  key={`tr-provider-${index}`}>
											<th scope="row">{provaider.id}</th>
											<td className="text-center">
												<img src="img-fluid" src={provaider.logo_url} height="50"/>
											</td>
											<td>
												{provaider.name}
											</td>
											<td>
												<p 
													className="card-text"
													dangerouslySetInnerHTML={{__html: provaider.description}}
													style={{whiteSpace:"pre-wrap"}}
												/>
											</td>
											<td>{provaider.created_at}</td>
											<td className="text-center">
												<button className="btn btn-primary mr-3" type="button" onClick={() => this.handleToggleModal('editProvaider',provaider)}>Editar</button>
												<button className="btn btn-danger mr-3" type="button" onClick={() => this.provaidersDelete(provaider.id)}>Elminar</button>
											</td>
										</tr>
								   	)
								},this)
								:
								<tr>
									<th scope="row" colSpan="6">Sin eventos</th>
								</tr>
							}
						</tbody>
					</table>
				</div>
				<div className="col-12 d-flex justify-content-center fixed-bottom pb-5"
						style={{
							marginBottom: '5rem',
						}}>
					{
						pagination != null ?
						<Paginate 
							last_page={pagination.last_page}
							current_page={pagination.current_page} 
							per_page={pagination.per_page}
							loadList={this.provaidersDetail}
							classNamew="border border-bottom-0 bg-white px-5 pt-4"
						/>
						:null
					}
				</div>
			</div>
		);
	}
}