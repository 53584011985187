import React, {Component} from 'react'
import Avatar from 'react-avatar-edit'
export default class InputFileImg extends Component {
	constructor(props) {
		super(props)
		let avatar=props.nameFile;
		let srcInput=props.srcInput;
		this.state = {
			accept: [
				'.jpg',
				'.png',
				'.jpeg',
			],
			[props.name]:null,
			nameFile: avatar ? avatar : null,
			srcInput: srcInput ? srcInput : null,
			iconClass:props.iconClass,
			className:props.className
		}
		this.handleChange = this.handleChange.bind(this)
	}
    componentDidMount() {
        if (localStorage.getItem("last_vote_image")) {
            var imgtag = document.getElementById("preview-q34e45");
            imgtag.src = localStorage.getItem("last_vote_image");

            const nameInput = localStorage.getItem("last_vote_image_name");
            const valueInput = localStorage.getItem("last_vote_image_value");
            const title = localStorage.getItem("last_vote_image_title");

       		this.setState({
                [nameInput]: valueInput,
                nameFile: title
            })
        }
    }

	componentDidUpdate(prevProps) {
		if (this.props.reset !== prevProps.reset) {
			var imgtag = document.getElementById("preview-q34e45")
			var input = document.getElementById("input-q34e45")
			input.value='';
			imgtag.src = this.props.srcInput ? this.props.srcInput: '#'
			this.setState({
				nameFile: this.props.nameFile ? this.props.nameFile: null,
			})
		}
	}
	async handleChange(event) {
		var title=event.target.files[0].name
		var nameInput=event.target.name
		var valueInput=event.target.value
		var selectedFile = await this.compressFile(event);
		var imgtag = document.getElementById("preview-q34e45");
		imgtag.title = title;
		imgtag.src=selectedFile.result
        localStorage.setItem("last_vote_image", selectedFile.result)
        localStorage.setItem("last_vote_image_title", title);
        localStorage.setItem("last_vote_image_name", nameInput)
        localStorage.setItem("last_vote_image_value", valueInput)
        
        console.log(nameInput, valueInput)

		this.setState({
			[nameInput]: valueInput,
			nameFile: title
		})
	}
	async compressFile(event) {
		return await new Promise((resolve, reject) => {
		    const width = 500;
		    //const height = 300;
		    const fileName = event.target.files[0].name;
		    const reader = new FileReader();
		    reader.readAsDataURL(event.target.files[0]);
		    reader.onload = event => {
		        const imgen = new Image();
		        imgen.src = event.target.result;
		        //console.log('Nueva imagen')
		        imgen.onload = () => {
	                const elem = document.createElement('canvas');
	                const scaleFactor = width / imgen.width;
					elem.width = width;
					elem.height = imgen.height * scaleFactor;
	                // elem.width = width;
	                // elem.height = height;
	                const ctx = elem.getContext('2d');
	                // img.width and img.height will contain the original dimensions
	                ctx.drawImage(imgen, 0, 0, width, imgen.height * scaleFactor);
	                //console.log('Render imagen')
	                ctx.canvas.toBlob((blob) => {
	                    const file = new File([blob], fileName, {
	                        type: 'image/jpeg',
	                        lastModified: Date.now()
	                    });

	                    var reader = new FileReader();
    					reader.readAsDataURL(file);
    					reader.onload = function () {
					        resolve(reader)
					    };
	                    
	                }, 'image/jpeg', 1);
	            }
	            reader.onerror = error => alert(error,'error en la imagen');
		    };

	    },event);
	}
	render() {
		const { nameFile, iconClass, srcInput,className } = this.state

		const classString = className===undefined ? 'input-file-custom' : className ;
		return (
			<>
            <span className={"font-weight-light"+( nameFile!=null ? ' font-italic' : '')}>
				{nameFile != null ? nameFile :'Selecciona una imagen (Opcional)'}
			</span>
			<label className={'mt-2 '+classString+(nameFile != null ? ' on' : '')+( iconClass ? ' '+iconClass : '')}>
				<input
					id="input-q34e45"
					name={this.props.name}
					type="file"
					onChange={this.handleChange} 
					accept={this.state.accept}
				/>
				<img id="preview-q34e45" alt={nameFile} className="preview" src={srcInput}/>
				{/*<i class="fas fa-pencil-alt edit-img"></i>*/}
			</label>
			</>
		)
	}
}


// function x
// document.getElementById("input-q34e45").addEventListener("change", function (event) {
// 	alert('Optimizando imagen')
// 	compres_file(event);
// });