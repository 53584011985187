
import React, {Component} from 'react';
import Session from '../Services/Session';
import Api from '../Services/Api';
import Paginate from '../Layout/Components/Paginate'

import  InputFileImg from '../Layout/Components/InputFileImg'
import { Spinner } from 'reactstrap'
import FormProcessing from '../Services/FormProcessing'
import { DateTime } from 'react-datetime-bootstrap';
import UserImg from '../Layout/Assets/user.png';
import { CSVLink, CSVDownload } from "react-csv";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export default class UsersDashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			counters: [
				{
					title:'Usuarios',
					value:'0',
                    button: <Link to="/users">Administrar</Link>
				},
				{
					title:'Broze',
					value:'0'
				},
				{
					title:'Silver',
					value:'0'
				},
				{
					title:'Golden',
					value:'0'
				},
				{
					title:'Total de Votos',
					value:'0'
				},
				,
			],
			last: [],
			users:[],
			csvData:[],
			headers:[
			  	{ label: "Voto id", key:"voto_id" },
				{ label: "Proveedor", key:"proveedor" },
				{ label: "Producto", key:"producto" },
				{ label: "Correo", key:"email" },
				{ label: "Valoración", key:"valoracion" },
				{ label: "Numero Boleta", key:"numero_boleta" },
				{ label: "Comentario", key:"comentario" },
				{ label: "Url foto", key:"foto" },
				{ label: "Hora de voto CL", key:"hora_de_votacion_santiago" },
				{ label: "Ip", key:"ip" },
				{ label: "Dispositivo", key:"dispositivo" },
				{ label: "País", key:"country" },
			],
			pagination:null,
            search: '',
		};
		this.getDetails = this.getDetails.bind(this);
		this.blockUser = this.blockUser.bind(this);
        this.unblockUser = this.unblockUser.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
	}
	UNSAFE_componentWillMount() {
		window.scrollTo(0,0)
		this.getDetails()
	}

    async blockUser(id){
        const { pagination  } = this.state
        if (window.confirm("¿Estas seguro de bloquear este usuario?")) {
            let response = await Api.blockUser(id)
            this.getDetails({page: pagination.current_page, search: this.state.search})
        }
    }

    async unblockUser(id){
        const { pagination  } = this.state
        if (window.confirm("¿Estas seguro de desbloquear este usuario?")) {
            let response = await Api.unblockUser(id)
            this.getDetails({page: pagination.current_page, search: this.state.search})
        }
    }
	async getDetails(page={}){
		let response = await Api.getUsers({page: page.page, search: this.state.search})
		const pagination = {
			last_page:response.last_page,
			current_page:response.current_page,
			per_page:response.per_page
		}
		this.state.counters[4].value=response.total_votings.cantidad;
		this.state.counters[1].value=response.bronze.cantidad;
		this.state.counters[2].value=response.silver.cantidad;
		this.state.counters[3].value=response.gold.cantidad;
		this.state.counters[0].value=response.users.cantidad;
		this.state.last=response.last.data;
		this.setState({
			users:response.data,
			pagination,
			modal:{
				toggle:false
			},
			counters:this.state.counters,
			last:this.state.last
		});
	}
	async listVoto(event_id=null){
		let response = await Api.listVoto({event_id})
		if(response?.error){
			alert("evento no encontrado")
		}
		this.setState({
			csvData:response,
		})
		this.setState({
			csvData:[],
		})
	}

    onSearch() {
        const { pagination } = this.state
        this.getDetails({search: this.state.search, page: pagination.current_page})
    }

    onChange(e) {
        const { pagination } = this.state
        this.setState({ search: e.target.value });
        this.getDetails({search: this.state.search, page: pagination.current_page})
    }
	
	render() {
		const { users, csvData, headers, pagination, search} = this.state
		const { blockUser, unblockUser, listVoto, onChange, onSearch } = this
		return(
			<div className="row">
				{ csvData.length>0 ? <CSVDownload data={csvData} headers={headers} target="_blank" filename={`lista_votos.csv`} /> : null}
                <div className="col">
                    <h1>Administrar usuarios</h1>
                </div>
				<div className="col-auto pt-3 text-right">
					<div className="btn-group" role="group" aria-label="...">
						<button className="btn btn-primary" type="submit" onClick={()=>listVoto()}>
							Desrcargar votos
						</button>
						<button className="btn btn-primary" type="submit" onClick={()=>listVoto()}>
							Desrcargar usuarios
						</button>
					</div>
				</div>
				<div className="col-12 col-md-12">

                    <div className="row">
                        <div className="col-md-12">
                            <div class="input-group">
                                <input type="text" 
                                        class="form-control" 
                                        placeholder="Email, Nombre o username" 
                                        value={search}
                                        onChange={onChange}/>
                                {/* <div class="input-group-append">
                                    <button class="btn btn-outline-secondary" type="button" onClick={onSearch}>Buscar</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
					<table className="table table-bordered table-hover">
						<thead>
							<tr>
								<th scope="col">#</th>
                                <th>Nombre</th>
                                <th>Email</th>
                                <th>Username</th>
							</tr>
						</thead>
						<tbody>
							{	users.length > 0 ? 
								users.map(function callback(user, index, array) {
								    return(
										<tr  valign="middle">
											<td scope="row" className={user.deleted_at ? 'bg-danger text-white' : ''}>{user.id}</td>
                                            <td scope="row">{user.name} {user.surname}</td>
                                            <td scope="row">{user.email}</td>
                                            <td scope="row">{user.username}</td>
											<td>
												<Link to={`/admin/users/${user.id}/votes`} className="mr-3">
                                                    Administrar votos
                                                </Link>

                                                <Link to={`/admin/user/${user.id}/edit`} className="mr-3">
                                                    Administrar usuario
                                                </Link>

                                                {
                                                    user.deleted_at ? <>
                                                        <button className="btn btn-primary btn-sm" type="submit" onClick={()=>unblockUser(user.id)}>
                                                            Desbloquear usuario
                                                        </button>
                                                    </> : <>
                                                        <button className="btn btn-danger btn-sm" type="submit" onClick={()=>blockUser(user.id)}>
                                                            Bloquear usuario
                                                        </button>
                                                    </>
                                                }
											</td>
										</tr>
								   	)
								})
								:
								<tr>
									<th scope="row" colspan="6">Sin usuarios</th>
								</tr>
							}
						</tbody>
					</table>
				</div>
				<div className="col-12 d-flex justify-content-center fixed-bottom pb-5"
						style={{
							marginBottom: '5rem',
						}}>
					{
						pagination != null ?
						<Paginate 
							last_page={pagination.last_page}
							current_page={pagination.current_page} 
							per_page={pagination.per_page}
                            search={search}
							loadList={this.getDetails}
							classNamew="border border-bottom-0 bg-white px-5 pt-4"
						/>
						:null
					}
				</div>
			</div>
		);
	}
}