import React, { Component } from 'react';
import { ReactComponent as CategoriaIcon } from '../Layout/Assets/medalla.svg';

import CountriesJson from '../Layout/Assets/json/countries.json'

import brandCL from '../Layout/Assets/brand-CL.png';
import brandPE from '../Layout/Assets/brand-PE.png';

import PartnerPE from '../Layout/Components/PartnerPE';
import PartnerCL from '../Layout/Components/PartnerCL';

export function GetDomains($item = null) {
	return {
		PE:"votaciones.thetop.com.pe",
		//PE:"127.0.0.1",
		CL:"votaciones.thetop.cl",
	}
}
export function GetCategoryUser(props) {
	var class_value = '';
	switch (true) {
		case props.countVoting <= 10:
			class_value = 'medalla bronze'
			break;
		case props.countVoting <= 20:
			class_value = 'medalla silver'
			break;
		case props.countVoting > 20:
			class_value = 'medalla gold'
			break;
		default:
			class_value = 'medalla-icon-white'
	}

	return (
		<CategoriaIcon
			className={class_value + ' ' + props.className}
			style={props.style ? { ...props.style } : {}}
		/>
	)
}
export function GetForCountry($item = null) {
	const domains = GetDomains()
	const CL = {
		brand: brandCL,
		partner: (new PartnerCL).render(),
		mainDomain: "https:\/\/thetop.cl",
		emailContact: "contacto@thetop.cl",
		facebook: "https:\/\/www.facebook.com\/thetopchile-589351524914705\/",
		instagram: "https:\/\/www.instagram.com\/thetopchile\/",
		allRightsReserved: "© The Top Chile. Todos los derechos reservados 2021",
		regions: true,
		alfa2: "CL",
		country: "Chile",
		blogLink: "https:\/\/thetop.cl\/blogs\/",
	};
	const PE = {
		brand: brandPE,
		partner: (new PartnerPE).render(),
		mainDomain: "https:\/\/thetop.com.pe",
		emailContact: "contacto@thetop.com.pe",
		facebook: "https:\/\/m.facebook.com\/thetopperu\/",
		instagram: "https:\/\/instagram.com\/thetopperu?igshid=ww65s1y8flyd",
		allRightsReserved: "© The Top Perú. Todos los derechos reservados 2021",
		regions: false,
		alfa2: "PE",
		country: "Perú",
		blogLink: "https:\/\/thetop.com.pe\/blogs\/",
	};
	switch (window.location.hostname) {
		case domains.PE:
			return PE;
		case domains.PE:
			return CL;
		default:
			return CL;
			break;
	}
}

export function GetMenuCountry($item = null) {
	const domains = GetDomains()

	switch (window.location.hostname) {
		case domains.PE:
			return GetMenuPE();
		case domains.CL:
			return GetMenuCL();
		default:
			return GetMenuCL();
			break;
	}
}

export function GetNumeric(number) {
	return new Intl.NumberFormat("de-DE").format(number)
}
export function GetNumericFloat(number) {
	const resul = parseFloat(number).toFixed(2)
	console.log('data', resul)
	return isNaN(resul) ? 0 : resul;
}
// Menu chile
export function GetMenuCL() {
	return [
		{
			name: 'EVENTOS THE TOP',
			link: '',
			sub: [
				{
					name: 'THE TOP BURGER',
					linkWp: 'https://thetop.cl/the-top-burguer/'
				},
				{
					name: 'THE TOP PIZZA',
					linkWp: 'https://thetop.cl/thetoppizza/'
				},
				{
					name: 'THE TOP SUSHI',
					linkWp: 'https://thetop.cl/the-top-sushi/'
				},
				{
					name: 'THE TOP SANDWICH',
					linkWp: 'https://thetop.cl/the-top-sandwich/'
				},
				{
					name: 'THE TOP ICE CREAM',
					linkWp: 'https://thetop.cl/the-top-icecream/'
				},
				{
					name: 'THE TOP BEER',
					linkWp: 'https://thetop.cl/the-top-beer-2021/'
				},
				{
					name: 'THE TOP TENTACIONES',
					linkWp: 'https://thetop.cl/the-top-tentaciones/'
				},
				{
					name: 'THE TOP EMPANADAS',
					linkWp: 'https://thetop.cl/the-top-empanadas/'
				},
			]
		},
		{
			name: 'PODIOS',
			linkWp: 'https://thetop.cl/paseo-de-las-estrellas/',
			sub: []
		},
		{
			name: 'INCRIBE TU RESTAURANT',
			linkWp: 'https://thetop.cl/inscribe-tu-restoran/',
			sub: []
		},
		{
			name: 'THE TOP JUEZ',
			link: '/juez',
			sub: []
		},
		{
			name: 'REVIEWS',
			link: '/reviews',
			sub: []
		},
		{
			name: 'THE TOP DEL MES',
			linkWp: 'https://thetop.cl/the-top-del-mes/',
			sub: []
		},
		// {
		//   name:'MI PERFIL',
		//   link:'/mi/perfil',
		//   sub:[]
		// },
		// {
		//   name:'ENTRAR A CUENTA',
		//   link:'',
		//   sub:[]
		// },
	];

}

// Menu perú
export function GetMenuPE() {
	// dejar 
	// mi perfil*,
	// votar,
	// thetopjuez*,
	// inicio*,
	// review*
	return [
		{
			name: 'INICIO',
			link: '/',
			sub: []
		},
		// {
		// 	name: 'THE TOP JUEZ',
		// 	link: '/juez',
		// 	sub: []
		// },
		// {
		// 	name: 'REVIEWS',
		// 	link: '/reviews',
		// 	sub: []
		// },
		{
			name: 'VOTAR',
			link: '/',
			sub: []
		},
	];

}

