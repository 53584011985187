import  React, { Component } from 'react';
import brand from '../Layout/Assets/logo_nav.png';
import { Link } from "react-router-dom";
import { Spinner } from 'reactstrap'
import  Api from '../Services/Api'
import FormProcessing from '../Services/FormProcessing'
import SweetAlert from 'sweetalert2-react';
import { GetForCountry }from '../Services/Helpers';

class SentEmailChangePassword extends Component {
	constructor(props) {
		super(props)
		this.state = {
			email: '',
			loading:false,
			message:'',
			showAlert:false,
		}
		this.form = React.createRef()
		this.handleChange = this.handleChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}
	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value,
		});
	}
	async handleSubmit(event){
		event.preventDefault();
		this.setState({
			loading: true
		});
		let validate = []
		let response = await Api.resetEmailPass({
			email:this.state.email,
		});
		if(response.enable_validations===true){
			validate = response
			FormProcessing.validate(this.form.current,validate)
		} 
		if(response.error){
			this.setState({
				loading: false,
				message:response.error
			});
		}else if (response.success){
			this.setState({
				loading: false,
				showAlert:true,
				message:response.success,
			});
		}else{
			this.setState({
				loading: false,
			});
		}
		
	}
	render(){
		return (
			<>
			<SweetAlert
				show={this.state.showAlert}
				title="¡Buenísimo!"
				text="Revisa tu correo para continuar con el cambio"
				type="success"
				onConfirm={() => this.setState({ showAlert: false })}
				confirmButtonText="Ok"
			/>
			<div className="sign-in container viewport-container h-100 d-flex align-items-center justify-content-center">
				<div className="col-10 col-sm-10 col-md-6 col-lg-3 px-2">
					<p className="text-center">
						<img src={GetForCountry().brand} alt="sdad" className="brand"/>
					</p>
					<form onSubmit={this.handleSubmit} ref={this.form}>
						<small id="emailHelp" className="form-text text-center text-danger mt-0 mb-3">
							{this.state.message}
						</small>
						<p className="form-group text-center py-3 mb-3">
							<small>
							Ingrese su correo electronico y le enviaremos
							<br/>
							Un enlace para restablecer su contraseña
							</small>
						</p>
						<div className="form-group">
							<input 
								type="email"
								className="custom-input form-control"
								aria-describedby="emailHelp"
								placeholder="Email"
								name="email"
								id="email"
								onChange={this.handleChange}
								value={this.state.email}
								equired={true}
							/>
						</div>
						<div className="form-group text-center py-2">
							<Link to="/sign/up" className="text-dark">
								Crear cuenta
							</Link>
						</div>
						<button
							type="submit"
							className="btn btn-block py-3 custom-btn-red custom-btn"
							disabled={this.state.loading ? true : false}
						>
							{ 
								this.state.loading === false ? 'enviar' :
								<span>
								<Spinner style={{ 
									width: '1rem',
									height: '1rem',
									top: '-4px',
									position: 'relative'
								}}/> Enviando..
								</span>

							}
						</button>
						<br/>
						<div className="form-group text-center">
							<Link to="/auth/sign/in" className="text-dark">
								Volver al inicio
							</Link>
						</div>
					</form>
				</div>
			</div>
			</>
		);
	}
}
export default SentEmailChangePassword;