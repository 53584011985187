import React, {Component} from 'react';
//import { Link } from 'react-router-dom';
//import Session from '../Services/Session';
import  { ReactComponent as CategoriaIcon } from '../Layout/Assets/icons/estrellaplata.svg';
//import Placeholder from '../Layout/Assets/placeholder.png';
import  MasterGold from '../Layout/Assets/MasterGold.png';
export default class Category extends Component {
	constructor(props) {
		super(props);
		this.state = {
			main: false,
			list:[
				{
					type:'bronze',
          title:'Catador',
					descrition:'Desde 1 Voto Hasta 10',
					puntos:[
            'Participando por Gran Premio TheTop',
            'Acceso a perfil gastronómico propio con posibilidad de seguir amigos y ser seguido',
            'Visibilidad de reviews ',
						// 'Participando por Gran Premio TheTop',
						// 'Recibe primero la información de cada evento',
						// 'Visibilidad comentarios',
					],
				},
				{
					type:'silver',
          title:'Foodie',
					descrition:'Desde 11 Voto Hasta 40',
					puntos:[
            'Mayores posibilidades de ganar Gran Premio TheTop',
            'Acceso a perfil gastronómico propio con posibilidad de seguir amigos y ser seguido',
            'Mayor visibilidad de reviews',
            'Foodie para futuros eventos de lanzamiento: probar gratis los productos TheTop. *Se hará sorteo entre los Silver y Gold',

						// 'Mayores posibilidades de ganar Gran Premio TheTop',
						// 'Recibe primero la información de cada evento',
						// 'Mayor Visibilidad comentarios',
						// 'Podrá optar a convertirse en Juez',
						// 'Foodie para futuros eventos: probar gratis los productos the top. * Se hará sorteo entre los silver y gold',
					],
				},
				{
					type:'gold',
          title:'Gourmet',
					descrition:'Más de 40 votos',
					puntos:[
            'Mayores posibilidades de ganar Gran Premio TheTop',
            'Acceso a perfil gastronómico propio con posibilidad de seguir amigos y ser seguido',
            'Mayor visibilidad de reviews',
            'Foodie para futuros eventos de lanzamiento: probar gratis los productos the top. *Se hará sorteo entre los Silver y Gold',
            'Invitación a activaciones exclusivas TheTop para probar productos . Solo Gold.',
            'Acceso a club de fidelización con descuentos y regalos exclusivos del mundo TheTop.',

						// 'Mayores posibilidades de ganar Gran Premio TheTop',
						// 'Recibe primero la información de cada evento',
						// 'Mayor Visibilidad comentarios',
						// 'Podrá optar a convertirse en Juez',
						// 'Foodie para futuros eventos: probar gratis los productos the top. * Se hará sorteo entre los silver y gold',
						// 'Invitación a los eventos de lanzamiento Eventos The Top a probar gratis productos antes que todos. Solo Gold',
					],
				},
				// {
				// 	type:'master',
				// 	descrition:'Desde los 51 votos',
				// 	puntos:[
				// 		'Un Master Gold debe tener como mínimo 5 votaciones en cada evento nuevo luego de haberse titulado como Master Gold, de manera que puede perder “el cetro” y tendrá seguro gente en la fila lista para quitarle el trono.',
				// 	],
				// },
			]
		};
	}
	render() {
		return (
			<div className="row">
			<div className="bg-light col-12 py-5 mt-negative-4">
			<h3 className="text-center text-danger my-3 pt-4">
				CATEGORÍAS THE TOP JUEZ
			</h3>
			<p className="text-center m-auto pb-5">
				Bienvenidos al club de los amantes y expertos de la gastronomía.
				Mientras más votes y expreses tu opinión podrás acceder a los beneficios.
			</p>
			<div className="row px-2">
				{this.state.list.map(function(item, i){
					return(
						<div className="mb-5 col-md-6 col-lg-4 px-1" key={"r-"+(i+1)}>
						<div className="card shadow bg-white rounded h-100">
							<div className="row no-gutters text-dark">
								<div className="col-12 p-1 text-center">
								{item.type=='master' ? <img src={MasterGold} className="img-fluid mb-3" style={{width:'75px',top: '-30px',position: 'relative'}}/> :
									<CategoriaIcon className={"medalla-page "+item.type}/>
								}
								</div>
								<div className="col-12">
									<div className="card-body py-0">
										<h5 className="card-title text-capitalize">
											{item.title} : {item.descrition} 
										</h5>
										<ul className="pl-3">
										{item.puntos.map(function(p, i){
											return(
												<li key={"t-"+(i+1)}>
												{p}
												</li>
											)
										})}
										</ul>
									</div>
								</div>
							</div>
						</div>
						</div>
					)
				})}
			</div>
			</div>
			</div>
		);
	}
}