import React, {Component} from 'react';
import Session from '../Services/Session';
import Api from '../Services/Api';
import  InputFileImg from '../Layout/Components/InputFileImg'
import { Spinner } from 'reactstrap'
import FormProcessing from '../Services/FormProcessing'
import { DateTime } from 'react-datetime-bootstrap';
import UserImg from '../Layout/Assets/user.png';
import Avatars from '../VoteHere/Avatars';
import {AvatarsList} from '../VoteHere/Avatars';
import { Link } from 'react-router-dom';

export default class FormEditUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			resetForm:false,
			userData:null,
			id:this.props.match.params.id,
			private:false,
            msgSuccess:null,
		};
		this.form = React.createRef()
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleAvatar = this.handleAvatar.bind(this);
        this.blockUser = this.blockUser.bind(this);
        this.unblockUser = this.unblockUser.bind(this);
	}
	componentDidMount(propsDev) {
		let p = this.props
		if(this.state.userData===null){
			this.loadUser(this.state.id)
		}
	}
	async loadUser(id){
		let data = await Api.showUserAdmin(id)
		if(data.warning){
			alert('usuario no encontrado!')
		}else{
			this.setState({
				userData:data,
				resetForm:!this.state.resetForm
			})
		}
	}
	async handleAvatar(data){
		let response = await Api.updateAvatarAdmin(this.state.id,{avatar_selected:data})
		this.loadUser(this.state.id)
	}
	async handleSubmit(e,id){
		e.preventDefault();
		
		this.setState({
			loading:true,
		})
		let data = await FormProcessing.toObject(this.form.current)
		let response = await Api.updateUserAdmin(id,data)
		

		let validate = []
		if(response.enable_validations===true){
			validate = response
			this.setState({
				loading:false,
			})
		}else{
			if (typeof response.username !== 'undefined') {
				Session.edit(response)
				this.setState({
					loading:false,
					userData:response,
					resetForm:!this.state.resetForm,
                    msgSuccess: 'Usuario actualizado correctamente'
				})
			}
		}
		FormProcessing.validate(this.form.current,validate)
	}
	handleInputChange(event) {
		const target = event.target;
		const value = target.value === 'private' ? target.checked : target.checked;
		const name = target.name;
		let userData = this.state.userData;
		userData[name]=value;
		console.log(userData);
		this.setState({
		  userData
		});
  	}

    async blockUser(id){
        const { pagination  } = this.state
        if (window.confirm("¿Estas seguro de bloquear este usuario?")) {
            let response = await Api.blockUser(id)
            this.loadUser(this.state.id)
        }
    }

    async unblockUser(id){
        const { pagination  } = this.state
        if (window.confirm("¿Estas seguro de desbloquear este usuario?")) {
            let response = await Api.unblockUser(id)
            this.loadUser(this.state.id)
        }
    }

	render() {
		const {resetForm, userData, loading, msgSuccess, id } = this.state
        const {blockUser, unblockUser} = this
		return(
            <>

                <div className="row mt-5">
                    <div className="col-md-12">
                        <form className="row" onSubmit={(e)=>this.handleSubmit(e,id)} ref={this.form}>

                            
                            <div className="bg-light col py-5 text-center mt-negative-4">
                                <div className="row mb-5">
                                    <div className="col-md-12">
                                        <Link to={`/users`}>Volver</Link>
                                    </div>
                                </div>
                                <h3 className="mt-4">Editar mi perfil</h3>
                                <InputFileImg 
                                name="avatar"
                                reset={resetForm}
                                srcInput={
                                    (userData ? ( 
                                        userData.avatar_selected ==null ? (userData.avatar_url == null ? UserImg : userData.avatar_url) : AvatarsList[userData.avatar_selected]
                                    ) : null)
                                }
                                nameFile={(userData ? 'Avatar de usuario' : null)}
                                className="input-file-radius"
                                />
                                <br />
                                {
                                    msgSuccess ? (
                                        <div className="col-12">
                                            <div className="alert alert-success" role="alert">
                                                {this.state.msgSuccess}
                                            </div>
                                        </div>
                                    ) : null
                                }   
                                <div className="form-group col px-0">
                                    <input 
                                        type="text"
                                        className="form-control py-4"
                                        name="username"
                                        aria-describedby="username"
                                        placeholder="Nombre usuario"
                                        defaultValue={ userData ? userData.username : '' } 
                                    />
                                </div>
                                <div className="form-group col px-0">
                                    <input 
                                        type="text"
                                        className="form-control py-4"
                                        name="name"
                                        aria-describedby="name"
                                        placeholder="Nombre"
                                        defaultValue={ userData ? userData.name : '' } 
                                    />
                                </div>
                                <div className="form-group col px-0">
                                    <input 
                                        type="text"
                                        className="form-control py-4"
                                        name="surname"
                                        aria-describedby="surname"
                                        placeholder="Apellido"
                                        defaultValue={ userData ? userData.surname : '' } 
                                    />
                                </div>
                                <div className="form-group col px-0">
                                    <input 
                                        type="email"
                                        className="form-control py-4"
                                        name="email"
                                        aria-describedby="email"
                                        placeholder="Correo"
                                        defaultValue={ userData ? userData.email : '' } 
                                    />
                                </div>
                                <div className="form-group col px-0">
                                    <input 
                                        type="text"
                                        className="form-control py-4"
                                        name="instagram"
                                        aria-describedby="instagram"
                                        placeholder="Cuenta instagram"
                                        defaultValue={ userData ? userData.instagram : '' } 
                                    />
                                </div>
                                <p className="col">
                                    Tu cuenta de instagram es para vincular las stories de los eventos 
                                    <i className="fa fa-info-circle text-danger"/>
                                </p>
                                <div className="form-group col px-0">
                                    <DateTime 
                                        name="birth_date"
                                        pickerOptions={{
                                            format:"YYYY-MM-DD",
                                        }} 
                                        placeholder="Fecha de Nacimento"
                                        aria-describedby="birthDate"
                                        value={ userData ? userData.birth_date : '' }
                                    />
                                </div>
                                {/*<div className="input-group mb-3 col px-0">
                                    <select
                                        className="custom-select ml-0"
                                        aria-label="Paises"
                                    >
                                        <option>Chile</option>
                                    </select>
                                    <select
                                        className="custom-select mr-0"
                                        name="comuna_id"
                                        aria-label="Region de Chile"
                                    >
                                        <option value="">Región/Comuna</option>
                                        <option value="">none</option>
                                    </select>
                                </div>*/}

                                
                                <button
                                    type="submit"
                                    className="btn custom-btn-red px-5 py-3"
                                    disabled={this.state.loading ? true : false}
                                >
                                    { 
                                        this.state.loading === false ? 'GUARDAR' :
                                        <span>
                                        <Spinner style={{ 
                                            width: '1rem',
                                            height: '1rem',
                                            top: '-4px',
                                            position: 'relative'
                                        }}/> GUARANDO..
                                        </span>

                                    }
                                </button>

                                <hr />
                                {
                                    userData && userData.deleted_at ? <>
                                        <button type="button" className="btn btn-primary btn-sm" onClick={()=>unblockUser(userData.id)}>
                                            Desbloquear usuario
                                        </button>
                                    </> : <>
                                        <button type="button" className="btn btn-danger btn-sm" onClick={()=>blockUser(userData.id)}>
                                            Bloquear usuario
                                        </button>
                                    </>
                                }
                                {/* <div className="form-check pb-3 px-3 mt-4">
                                    <input
                                        
                                        type="checkbox"
                                        name="private"
                                        id="private1"
                                        defaultValue="1"
                                        checked={ userData ? userData.private : this.state.private }
                                        onChange={this.handleInputChange}
                                    />
                                    <br/>
                                    <label className="form-check-label" htmlFor="exampleRadios1">
                                        DESEO QUE MI PERFIL SEA PRIVADO
                                    </label>
                                </div> */}
                            </div>
                            </form>
                    </div>
                </div>
            </>

		)
	}
}