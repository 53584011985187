import React, { Component } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

class Paginate extends Component {
	constructor(props) {
		super(props);
		let pages=[];
		let last=props.last_page
		let current=props.current_page
		let hasta = current + 3 <= last ? current + 3 : last;
		let desde = current - 3 >= 1 ? current - 3 : 1;
		for ( var i = desde; i <=hasta; i++) {
		  	pages[i]={page:i};
		}
		this.state = {
			last_page:props.last_page,
			current_page:props.current_page,
			per_page:props.per_page,
			pages
		};
		this.getResuls = this.getResuls.bind(this);
	}
	UNSAFE_componentWillReceiveProps(props) {
		let pages=[];
		let last = props.last_page
		let current = props.current_page
		let hasta = current + 3 <= last ? current + 3 : last;
		let desde = current - 3 >= 1 ? current - 3 : 1;
	    for ( var i = desde; i <=hasta; i++) {
		  	pages[i]={page:i};
		}
		this.setState({
			last_page:props.last_page,
			current_page:props.current_page,
			per_page:props.per_page,
			pages
		});
	}
	async getResuls(e,page){
		e.preventDefault();
		await this.props.loadList(page)
	}
	render() {
		var nex={};
		const { last_page } = this.state
	    return (
			<Pagination aria-label="Page navigation example" className={this.props.className+" shadow-lg"} listClassName="mb-0">
				<PaginationItem>
					<PaginationLink onClick={ e => this.getResuls(e,{page:1}) }>
						{'<<'}
					</PaginationLink>
				</PaginationItem>
				<PaginationItem>
					<PaginationLink previous onClick={ e => this.getResuls(e,
						(this.state.current_page-1<1 ? {page:null} : {page:this.state.current_page-1})
					) } />
				</PaginationItem>
				{ typeof this.state.pages === 'object' ? 
					this.state.pages.map((p, i) => {
						if(i===this.state.current_page){
							nex=(1+i>this.state.last_page ? {page:i} :{page:1+i});
							return(
								<PaginationItem key={i} active>
									<PaginationLink onClick={ e => this.getResuls(e,p) } >
										{i}
									</PaginationLink>
								</PaginationItem>
							)
						}else{
							return(
								<PaginationItem key={i}>
									<PaginationLink onClick={ e => this.getResuls(e,p) } >
										{i}
									</PaginationLink>
								</PaginationItem>
							)
						}
						
					}):null
				}
				<PaginationItem>
					<PaginationLink next onClick={ e => this.getResuls(e,{page:nex}) }/>
				</PaginationItem>
				<PaginationItem>
					<PaginationLink onClick={ e => this.getResuls(e,{page:last_page}) }>
						{'>>'}
					</PaginationLink>
				</PaginationItem>

			</Pagination>
		);
	}
}
export default Paginate;