import React, { useEffect, useState } from 'react';
import { Button, Rate } from 'rsuite';
import { Link } from 'react-router-dom';

import { GetCategoryUser }from '../../Services/Helpers';
import Api from '../../Services/Api';
import Icons from '../../Components/Icons'
import UserImg from '../../Layout/Assets/user.png';
import {AvatarsList} from '../../VoteHere/Avatars';
import Slider from "react-slick";


const WidgetCommunity  = (props) => {
  const [ list, setList ] = useState([]);
  useEffect(()=>{
    const run = async () => {
      let response = await Api.getUsersListing({lot:(props.amount ? props.amount : 2)});
      setList(response.data);
    }
    run()
  },[]);

  return(
    <div className="row pt-4 justify-content-md-center">
      <div className="col-12">
        <h4 className="mb-4">
          <Link to="/todos/juez" className="h4">
          Comunidad
          </Link>

          <Link to="/todos/juez" className="float-right">
            <i class="fas fa-arrow-right"/>
          </Link>
        </h4>
      </div>
      <div className="col-12 col-md-12">
        <TypeLayout list={list} {...props}/>
      </div>
    </div>
  );
}

export default WidgetCommunity;
const TypeLayout = (props) => {

  if (props.slider) {
    const settings1 = {
      infinite: true,
      speed: 500,
      autoplay:true,
      autoplaySpeed: 1800,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      className: "center",
      centerMode: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerPadding: "80px",
      responsive: [
        {
          breakpoint: 2000, // xxx-large
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            centerMode:true,
            className: "center",
            centerPadding: "0px",
            initialSlide: 2,
          }
        },
        {
          breakpoint: 720, // medium
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 576, // small
          settings: {
            className: "center",
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "80px",
          }
        },
      ]
    };
    return(
      <div className="widget-community">
      <Slider {...settings1}>
        {
          props.list.map((item,i)=>{
            return(
              <div className="py-4" key={`slick-item-${i}`}>
                <div className="slick-item-main" key={`slick-item-${i}`}>
                <LayoutProfile {...item} addclass="col-12"/>
                </div>
              </div>
            )
          })
        }
      </Slider>
      </div>  
    )
  }

  return(
    <div className="row" style={{
      marginRight: '-0.25rem',
      marginLeft: '-0.25rem',
    }}>
      {
        props.list.map((item)=>{
          return(
            <LayoutProfile {...item}/>
          )
        })
      }
    </div>
  )
}

const LayoutProfile = (props) => {
  const Votings = () => {
    let VotingsHtml = []
    typeof props.voting === 'object' && props.voting.length > 0 ? 
    props.voting.map(function(v, index) {
        //v.product.logo_url
        //src={v.product.logo_url}
        VotingsHtml.push(
          <div
            className="col-4 px-1 py-1"
            key={index+1}
          >
            <Link to={"/juez/votacion/"+v.id}>
            { v.photo_url!=null ?
              <img 
                src={v.photo_url}
                width="100%"
                alt="Foto de Productos"
                className="img-responsive h-100"
                style={{
                  objectFit:'cover',
                  minHeight: '65px',
                  maxHeight: '65px',
                }}
              />
              :
              <img 
                
                src={v.product.logo_url}
                width="100%"
                alt="Foto de Productos"
                className="img-responsive h-100"
                style={{
                  objectFit:'cover',
                  minHeight: '65px',
                  maxHeight: '65px',
                }}
              />
            }
            <img 
            alt=""
            className="img-fluid"
            src={v.restaurant.logo_url}
            style={{
              "position": "absolute",
              "width": "35px",
              "left": "-3.3px",
              "top": "-3.3px",
              "background":"white none repeat scroll 0% 0%",
              //"border": "1px solid  #dee2e6",
              "borderRadius": "50%",
              "padding": "5px",
            }}/>
            </Link>
          </div>
        )
        return true
      },VotingsHtml)
    :
      VotingsHtml.push(
      <div className="col-12 px-1 py-5">
        <i className="far fa-frown fa-3x"></i>
        <h3 className="text-center">Sin votos</h3>
      </div>
      )
    return VotingsHtml;
  }
  return(
    <div className={`${props.addclass ? props.addclass : 'col-6 col-md-3 col-lg-2'} px-1 item-user`}>
      <div className="row mx-0">
        <div className="col bg-light py-2 text-center">
            <h5 className="mt-4">Perfil</h5>
            <div className="row mx-0 justify-content-center">
            <div className="col-auto">
            <img 
              src={props.avatar_selected == null ? (props.avatar_url == null ? UserImg : props.avatar_url) : AvatarsList[props.avatar_selected]}
              width="50"
              height="50"
              alt="Foto de perfil"
              className="img-responsive rounded-circle"
              style={{objectFit: 'cover',}}
            />
            <GetCategoryUser className="aling-bottom " countVoting={props.count_voting} style={{width: '1.6rem'}} />
            </div>
            </div>
            <h5 className="">
              {
                typeof props.full_name !== 'undefined' ?
                    props.full_name.length === 1 ?
                      props.username :
                        props.full_name : props.username
              }
            </h5>
            <p>
              <small>
              {props.username}
              </small>
            </p>
            <Link
              className="btn custom-btn-red"
              onClick={(e) => null}
              to={`/juez/${props.username}`}
            >
              Perfil
            </Link>
        </div>
        <div className="col-12 text-center py-3 px-0">
          <div className="row mx-0 kpi-users">
            <div className="col-4 small px-0">
              <h5>
                {props.count_voting}
              </h5>
              <span className="small title">Votos</span>
            </div>
            <div className="col-4 small px-0">
              <Link to={"/seguidores/de/"+props.username } className="small">
                <h5>
                  {props.count_followers}
                </h5>
                <span className="small title">Seguidores</span>
              </Link>
            </div>
            <div className="col-4 small px-0">
              <Link to={"/seguidos/por/"+props.username } className="small">
                <h5>
                  {props.count_followed}
                </h5>
                <span className="small title">Seguidos</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-12 text-center">
					<div className="row">
						<div className="col-12 mb-4">
							<small className="h5">votaciones</small>
							<hr className="my-0"/>
							<div className="px-5 w-25 py-1 bg-danger m-auto">
							</div>
						</div>
						<Votings/>
					</div>
				</div>
      </div>
    </div>
  )
}


export const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={style}
      onClick={onClick}
    >
    <i class="fas fa-chevron-right text-danger" style={{
      fontSize: '3rem',
    }}></i>
    </div>
  );
}

export const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
   <div
      className={className}
      style={style}
      onClick={onClick}
    >
    <i class="fas fa-chevron-left text-danger" style={{
      fontSize: '3rem',
    }}></i>
    </div>
  );
}