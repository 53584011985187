import  React, { Component } from 'react';

//import placeholder_restaurant from '../Layout/Assets/placeholder-restaurant.jpg';

import  InputFileImg from '../Layout/Components/InputFileImg'
import  FormProcessing from '../Services/FormProcessing'
import { Spinner } from 'reactstrap'
import SweetAlert from 'sweetalert2-react';
import { Link } from 'react-router-dom';
import { Button, Rate } from 'rsuite';
import {GetNumericFloat} from '../Services/Helpers';

import Api from '../Services/Api';
import Paginate from '../Layout/Components/Paginate'
import UserImg from '../Layout/Assets/user.png';

class Product2 extends Component {
	constructor(props) {
		super(props)
		this.state = {
			slug: this.props.match.params.slug,
			product: false,
			votings: false,
			loading:true,
			restaurant:false,
			loadingForm:false,
			showAlert: false,
			showAlertType: 'success',
			pagination:null,
			resetForm:false,
			lock_voting:0
		}
		this.productDetail = this.productDetail.bind(this)
		this.form = React.createRef()
		this.handleSubmit = this.handleSubmit.bind(this)
	}
	UNSAFE_componentWillMount() {
		window.scrollTo(0,0)
		this.productDetail({},this.state.slug)
	}
	async productDetail(page={},slug=null){
		slug = slug==null ? this.state.slug : slug;
		let response = await Api.product2(slug,page)
		let votings = false
		let pagination = null
		if(typeof response.votings === 'object'){
			votings=!!response.votings.data.length ? response.votings.data : false;
			pagination=!!response.votings.data.length ? {
				last_page:response.votings.last_page,
				current_page:response.votings.current_page,
				per_page:response.votings.per_page
			} : null;
		}
		this.setState({
			product:response.product,
			votings,
			pagination,
			loading:false,
			showAlert:false,
			lock_voting:response.lock_voting,
			restaurant: !! response.product.restaurant.length ? response.product.restaurant[0] : null ,
		})
	}
	async handleSubmit(e){
		e.preventDefault()
		this.setState({loadingForm:true})
		let data = await FormProcessing.toObject(this.form.current)
		let response = null
		let state = {}
		let validate = []
		response = await Api.voteProduct(data)
		if(response.enable_validations===true){
			validate=response
		}
		FormProcessing.validate(this.form.current,validate)
		if(response.success){
			state={
				success: response.success,
				showAlertType: 'success',
				showAlert:true,
				resetForm:!this.state.resetForm
			}
			this.form.current.reset()
		}
		if(response.error){
			state={
				success: response.error,
				showAlertType: 'error',
				showAlert:true,
				resetForm:!this.state.resetForm
			}
		}
		state.loadingForm = false
		this.setState(state)
	}
	render(){
		let {
			product,
			restaurant,
			loadingForm,
			votings,
			showAlert,
			success,
			showAlertType,
			pagination,
			resetForm,
			lock_voting,
			slug
		} = this.state
		return (
			<div className="row mt-5">
				<SweetAlert
					show={showAlert}
					title="Wow!"
					type={showAlertType}
					text={success}
					onConfirm={(e) => this.productDetail(this.props.match.params.slug)}
				/>
				<div className="col-md-12">
          <a href="#" onClick={(e)=> {e.preventDefault();window.history.back()}} className="text-dark">
            <i className="fas fa-arrow-left "/> volver
          </a>
					<h1 className="text-danger">
						{product.name}
					</h1>
					<hr/>
					<p>
						<strong>Restaurante: </strong>{restaurant.name}
					</p>
				</div>
				<div className="col-md-4">
					<img alt="" className="img-fluid border" src={product.logo_url}/>
					<div className="mt-4">
						<Rate value={product.promedio_estrellas} allowHalf readOnly size="xs"/>{' '}
						<small className="small rate-item">{GetNumericFloat(product.promedio_estrellas)}</small>
						<br />
						<small className="small rate-item">reviews: {product.total_votos}</small>
						<br/>
						<u>Descripción</u>
						<br/>
						<p 
							className="card-text"
							dangerouslySetInnerHTML={{__html: product.description}}
							style={{whiteSpace:"pre-wrap"}}
						/>
					</div>
				</div>
				{/*<form 
				className={"col-md-8"+(lock_voting===0 ? '' : ' d-none')}
				onSubmit={this.handleSubmit} ref={this.form} 
				>
					{
						typeof product ==="object" ? 
						<input name="product_id" defaultValue={product.id} className="d-none"/>
						:
						''
					}
					<p className="text-danger text-right">
						Completa el formulario para votar!
					</p>
					<label>
							Cantidad de estrellas *
					</label>
					<br/>
					<div className="rate px-0">
					    <input type="radio" id="star5" name="calificacion" value="5" />
					    <label htmlFor="star5" title="text">5 stars</label>
					    <input type="radio" id="star4" name="calificacion" value="4" />
					    <label htmlFor="star4" title="text">4 stars</label>
					    <input type="radio" id="star3" name="calificacion" value="3" />
					    <label htmlFor="star3" title="text">3 stars</label>
					    <input type="radio" id="star2" name="calificacion" value="2" />
					    <label htmlFor="star2" title="text">2 stars</label>
					    <input type="radio" id="star1" name="calificacion" value="1" />
					    <label htmlFor="star1" title="text">1 star</label>
					</div>
					<br/>
					<br/>
					<br/>
					<div className="form-group mt-2">
						<label>
							Número de boleta *
						</label>
						<input name="numero_de_boleta" className="form-control"/>
					</div>
					<div className="form-group mt-4 pt-2">
						<label>
							Comentario (opcional)
						</label>
						<textarea className="form-control" rows="5" name="description">
						</textarea>
					</div>
					<div className="form-group">
						<InputFileImg
							name="photo"
							iconClass="img text-center d-block"
							reset={resetForm}
						/>
					</div>

					<div className="form-group">
						<button
							className="btn btn-success px-5 btn-block"
							disabled={loadingForm || lock_voting ? true : false}
						>
							<span className="px-5">
							{
								loadingForm ?
								<span className="h3">
								<Spinner size="sm" color="white" style={{ 
										width: '1.5rem',
										height: '1.5rem',
										top: '-7px',
										position: 'relative',
										right:'9px'

								}}/> 
								Votando..
								</span>:
								<span className="h3">
								Votar
								</span>
							}
							</span>
						</button>
					</div>
            </form>*/}
				<div className={lock_voting===0 ? 'd-none' : 'col-md-8 text-center'}>
					<h1 className="my-5 py-5">
						<i className="fa fa-check  fa-2x text-success"/>
						<br/>
						Gracias
						<br/>
						Por participar
					</h1>
				</div>
				<div className="col-md-12">
					<hr/>
					<h1 className="text-center">Votos</h1>
					<hr/>
				</div>
				{
					typeof votings === 'object' ?
						votings.map(function(v, i){
							var rating = []
							for (var j = 1; j <= 5; j++) {
								if(v.rating>=j){
									rating.push(
										<i
											className="fas fa-star fa-lg text-warning"
											key={'s-'+j}
										/>
									)
								}else{
									rating.push(
										<i className="far fa-star fa-lg text-warning" key={'s-'+j}/>
									)
								}
							}
							//console.log(v.description)
							return (
								<div className="col-12" key={'v-'+i}>
								<Link className="row" to={"/juez/"+v.user.username}>
								<div className="col-md-12 mt-4 px-1 text-dark">
									<div className="card mb-3">
										<div className="row no-gutters">
											<div className="col-md-2 text-center">
												{ v.media_id !== null ?
													<img 
														className="img-fluid border"
														alt={v.user.username+"-my-voto-"+slug}
														src={v.photo_url}/>
													: 
													<h5 className="pt-5">
														Sin foto
													</h5>
												}
											</div>
											<div className="col-md-8">
												<div className="card-body">
													<div className="card-title">
														<img alt="" 
														className="img-fluid border" 
														width="50"
														src={v.user.avatar_url === null ? UserImg : v.user.avatar_url}/>
														{rating}
														<br/>
														<strong className="h3">
															{ v.user.username }
														</strong>
													</div>
													<p 
														className="card-text"
														dangerouslySetInnerHTML={{__html: v.description}}
														style={{whiteSpace:"pre-wrap"}}
													/>

													<p className="card-text">
														<small
															className="text-muted"
														>
														fecha de votacion: {v.created_at}
														</small>
													</p>
													
												</div>
											</div>
										</div>
									</div>
								</div>
								</Link>
								</div>
							)
						},slug)
					:
					<div className="col-md-12">
						<h3 className="text-center text-info">
						<i className="fa fa-close fa-lg"></i>
						<br/>
						Sin votaciones
						</h3>
					</div>
				}
				<div className="col-12 d-flex justify-content-center">
					{
						pagination != null ?
						<Paginate 
							last_page={pagination.last_page}
							current_page={pagination.current_page} 
							per_page={pagination.per_page}
							loadList={this.productDetail}
							classNamew="border border-bottom-0 bg-white px-5 pt-4"
						/>
						:null
					}
				</div>
			</div>
		)
	}
}
export default Product2;