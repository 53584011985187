import  React, { Component } from 'react';
import brand from '../Layout/Assets/logo_nav.png';
import Footer from '../Layout/Components/Footer';
import { Link } from "react-router-dom";
import { Spinner } from 'reactstrap';
import SweetAlert from 'sweetalert2-react';

import  Api from '../Services/Api'
import Session from '../Services/Session';
import FormProcessing from '../Services/FormProcessing'
import { GetForCountry }from '../Services/Helpers';

class ResetPassword extends Component {
	constructor(props) {
		super(props)
		this.state = {
			token: this.props.match.params.token,
			loading:true,
			error:'',
			showAlert:false,
			success:'',
			habilitado:false,
			redirect:false
		}
		this.form = React.createRef()
		this.handleSubmit = this.handleSubmit.bind(this)
	}
	UNSAFE_componentWillMount() {
		window.scrollTo(0,0)
		this.checkToken(this.state.token)
	}
	async checkToken(token){
		let response = await Api.checkTokenReset({token});
		if (response.success) {
			this.setState({
				habilitado: true,
				loading:false
			});
		}else{
			this.setState({
				loading:false
			});
		}
	}
	async handleSubmit(event){
		event.preventDefault();
		var userAgent = navigator.userAgent || navigator.vendor || window.opera;
		this.setState({
			loading: true
		});
		let data = await FormProcessing.toObject(this.form.current)
		data.user_agent=userAgent;
		data.token=this.state.token;
		let validate = []
		let response = await Api.changePass(data);
		if(response.enable_validations===true){
			validate = response
		} 
		FormProcessing.validate(this.form.current,validate)
		if(response.error){
			this.setState({
				loading: false,
				error:response.error
			});
		}else if (response.token){
			Session.open(response)
			this.setState({
				loading: false,
				showAlert:true,
				success:response.success
			});
		}else{
			this.setState({
				loading: false,
			});
		}
	}
	render(){
		const {loading,habilitado,redirect,showAlert,success} = this.state;
		if(redirect){
			window.location.href='/vota-aca';
		}
		return (
			<div className="container-fluid h-100">

				<SweetAlert
					show={showAlert}
					title="¡Buenísimo!"
					type="success"
					text={success}
					onConfirm={() => this.setState({ redirect: true })}
					confirmButtonText="Ir a votar"
				/>
				{
					habilitado===true ?
					<div className="row align-items-center justify-content-md-center h-100">
						<form className="col-12 col-md-8 col-lg-6 text-center text-white p-5" onSubmit={(e)=>this.handleSubmit(e)} ref={this.form}>
							<img alt="Logo" className="img-fluid mb-5" width="190" src={GetForCountry().brand}/>
							<div className="form-group">
								<input 
									type="password"
									className="form-control"
									name="nueva_clave"
									aria-describedby="emailHelp"
									placeholder="Nueva Clave" />
							</div>
							<div className="form-group">
								<input 
									type="password"
									className="form-control" 
									name="configmacion_de_clave"
									placeholder="Confirme su Clave" />
							</div>
							<div className="form-check">
							</div>
							<button type="submit" className="btn btn-success btn-block" disabled={loading ? true : false}>
								{ 
									loading === false ? 'Cambiar' :
									<span>
									<Spinner style={{ width: '1rem', height: '1rem' }}/> Cambiando..
									</span>

								}
							</button>
							<br/>
							<span className="text-dark">Para iniciar sesión debes cambiar la contraseña*</span>
							<br/>
							<Link to="/sign/in">Volver al inicio</Link>
						</form>
					</div>
					:
					(
						loading===true ?
						<div className="row align-items-center justify-content-md-center h-50 text-center">
							<div className="col py-5">
							<h3>
							Verificando..
							</h3>
							<br/>
							<Link to="/sign/in">Volver al inicio</Link>
							</div>
						</div>
						:
						<div className="row align-items-center justify-content-md-center h-50  text-center">
						<div className="col py-5">
						<h3>
						Link no disponible
						</h3>
						<br/>
						<Link to="/sign/in">Volver al inicio</Link>
						</div>
						</div>
					)
				}
			<Footer />
			</div>
		);
	}
}
export default ResetPassword;