import React from 'react';
import PropTypes from 'prop-types';
//import { SelectPicker } from 'rsuite';

const SelectSimple = (props) => {
    const {
        data, placeholder, className, name,
        size, type, runChange, initValue, parent, nameParentId
    } = props;

    //const [option, setOption] = useState(null);

    // const onChangeValue = (item,event) => {
    //     setOption(item)
    // };
    const onChangeValue = (e) => {
        runChange(e)
    };


    // console.log(size)
    switch (type) {
        case 'rsuite-picker':
            // let disabledItemValues=[]
            // for (const prop in data) {
            //     if (data[prop].disabled===true) {
            //         disabledItemValues.push(data[prop].value)
            //     }
            // }
            return (
                <>
                    {/*<SelectPicker
                        data={data}
                        placeholder={`${placeholder}`}
                        size={`${size}`}
                        className={`w-100 ${className}`}
                        onSelect={(item,event) => ( onChangeValue(item,event) )}
                        onClean={(item,event) => ( onChangeValue(null,event) )}
                        disabledItemValues={disabledItemValues}
                        defaultValue={defaultValue}
                    />
                    <input id={`filtrable-${name}`} name={name} type="hidden" value={option}></input>*/}
                </>
            );

        case 'simple':
            return (
                <select
                    className={`custom-select  custom-select-sm ${className} ${size}`}
                    name={name}
                    /*defaultValue={initValue ? initValue.toString() : null}*/
                    value={initValue ? initValue.toString() : ''}
                    onChange={onChangeValue}
                >
                    <option value="">{placeholder}</option>
                    {
                        data.length > 0 ? data.map(((t, i) => {
                            const label = t.label;
                            const value = t.value;
                            const parentValue = nameParentId ? t[`${nameParentId}`] : undefined;
                            if (parentValue!==parent) {
                                return true;
                            }
                            if (t.disabled) {
                                return (
                                    <option key={`select-${name}-${i}`} value={value.toString()} disabled={true}>
                                        {/*t.value+'-'+label*/}
                                        { label } disabled
                                    </option>
                                );
                            }
                            if (`${value}`===`${initValue}`) {
                                 return (
                                    <option key={`select-${name}-${i}`} value={value.toString()} /*{...{selected:true}}*/>
                                        {/*t.value+'-'+label*/}
                                        { label }
                                    </option>
                                );
                            }
                            return (
                                <option key={`select-${name}-${i}`} value={value.toString()}>
                                    { /* t.value+'-'+label */}
                                    { label }
                                </option>
                            );
                        })) : null
                    }

                </select>
            );
        default:
            return (
                <strong className="d-block px-3">Define el tipo de select</strong>
            )
    }
   
}

SelectSimple.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any
    })),
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.string,
    type: PropTypes.string,
};

SelectSimple.defaultProps = {
    data: [],
    placeholder: 'Elije una opcion',
    name: 'input',
    size: 'md-b',
    type: ''
};

export default SelectSimple;