import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";

import Api from "../Services/Api";
import Session from "../Services/Session";
import FormProcessing from "../Services/FormProcessing";
import brand from "../Layout/Assets/logo_nav.png";
import { GetForCountry } from "../Services/Helpers";

class SignIn extends Component {
  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(window.location.search);
    const returnUrl = urlParams.get("return_url");
    this.state = {
      loading: false,
      slug: returnUrl,
      message: "",
      isAdmin: props.profile == "admin",
      return_url: props.match.params.return_url
        ? props.match.params.return_url
        : "",
    };
    console.log(props);
    this.form = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loginSocial = this.loginSocial.bind(this);
  }
  async handleSubmit(event) {
    event.preventDefault();
    const urlParams = new URLSearchParams(window.location.search);
    const returnUrl = urlParams.get("return_url");

    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    this.setState({
      loading: true
    });
    let data = await FormProcessing.toObject(this.form.current);
    data.user_agent = userAgent;
    data.return_url = returnUrl;
    data.type_profile = this.state.isAdmin ? this.props.profile : "juez";
    let validate = [];
    let response = await Api.signIn(data);
    if (response.enable_validations === true) {
      validate = response;
    }
    FormProcessing.validate(this.form.current, validate);
    if (response.error) {
      this.setState({
        loading: false,
        message: response.error,
      });
    } else if (response.token) {
      Session.open(response);
      this.setState({
        loading: false,
      });
      localStorage.setItem("fecha_Nacimiento", response.fechaNacimiento!=null ? response.fechaNacimiento:"");
      localStorage.setItem("rut", response.rut!=null ? response.rut:"");
      localStorage.setItem("name", response.name!=null ? response.name:"");
      localStorage.setItem("surname", response.surname!=null ? response.surname:"");
      if (returnUrl) {
        window.location.href = returnUrl;
        return;
      }

      window.location.href = "/vota-aca";
    } else {
      this.setState({
        loading: false,
      });
    }
  }
  async loginSocial(type) {
    const res = await Api.getLinkSocial({
      product_id: this.state.slug,
      type: type,
      redirect_uri: window.location.href,
    });
    console.log("RETURN URL: ", this.state.slug)
    localStorage.setItem("last_product_id", this.state.slug);
    console.log(res.url);
    if (res.url) {
      window.location.replace(res.url);
      return;
    }
    window.location.href = "/vota-aca";
  }
  render() {
    const Logo = (props) => {
      return (
        <img
          src={GetForCountry().brand}
          alt="sdad"
          className="brand"
          {...props}
        />
      );
    };
    const { loginSocial } = this;
    return (
      <>
        <div className="sign-in container viewport-container h-100 d-flex align-items-center justify-content-center">
          <div className="col-10 col-sm-10 col-md-6 col-lg-3 px-2">
            <div className="text-center">
              {this.state.isAdmin ? (
                <div>
                  <Logo style={{ width: "36%" }} />
                  <h1>Administrador</h1>
                </div>
              ) : (
                <Logo />
              )}
            </div>
            <form onSubmit={(e) => this.handleSubmit(e)} ref={this.form}>
              <small
                id="emailHelp"
                className="form-text text-center text-danger mt-0 mb-3"
              >
                {this.state.message}
              </small>
              <p className="text-center">
                ¿Ya te haz registrado? ¡Inicia sesión!
              </p>
              <div className="form-group pb-3">
                <input
                  type="email"
                  className="custom-input form-control"
                  aria-describedby="emailHelp"
                  placeholder="Email"
                  name="email"
                  id="email"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="custom-input form-control"
                  aria-describedby="password"
                  placeholder="Contraseña"
                  name="password"
                  id="password"
                  required
                />
              </div>
              <button
                type="submit"
                className="btn btn-block py-3 custom-btn-red custom-btn"
                disabled={this.state.loading ? true : false}
              >
                {this.state.loading === false ? (
                  "Inicia sesión"
                ) : (
                  <span>
                    <Spinner
                      style={{
                        width: "1rem",
                        height: "1rem",
                        top: "-4px",
                        position: "relative",
                      }}
                    />{" "}
                    Iniciando..
                  </span>
                )}
              </button>
              <div className="form-group text-center py-2">
                <Link to="/sign/up" className="text-dark">
                  Crear cuenta
                </Link>
              </div>
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "-20px 0 10px 0",
                }}
              >
                <button
                  className="btn border m-1 bg-white"
                  type="button"
                  onClick={() => loginSocial("google")}
                  style={{ width: "215px" }}
                >
                  <img
                    width="20px"
                    alt="Google sign-in"
                    src={process.env.PUBLIC_URL + "/google.svg"}
                  />
                  <span className="ml-1">Iniciar sesión con Google</span>
                </button>
              </div>

              <div className="form-group text-center">
                <Link to="/enviar/mail" className="text-dark">
                  Olvidé mi Contraseña
                </Link>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}
export default SignIn;
