import React, { useEffect, useState } from 'react';
import Api from '../../Services/Api';
import { Link } from 'react-router-dom';
import { SampleNextArrow,SamplePrevArrow }from './WidgetCommunity'
import Slider from "react-slick";
import { GetNumericFloat } from "../../Services/Helpers";
import { Rate } from "rsuite";

const WidgetSuppliers  = (props) => {
  const [ list, setList ] = useState([]);
  useEffect(()=>{
    const run = async () => {
      let response = await Api.restaurantsListing({lot:30});
      setList(response.data);
    }
    run()
  },[]);

  const settings1 = {
    infinite: true,
    speed: 1000,
    autoplay:true,
    autoplaySpeed: 6000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 2000, // xxx-large
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: true,
          dots: true,
          initialSlide: 5,
        }
      },
      {
        breakpoint: 720, // medium
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 576, // small
        settings: {
          className: "center",
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "80px",
        }
      },
    ]
  }

  return(
    <div className="row pt-4 pb-5">
      <div className="col-12">
        <h4 className="">
          <Link to="/reviews" className="h4">
            Restaurants y marcas  
          </Link>
          <Link to="/reviews" className="float-right">
            <i class="fas fa-arrow-right"/>
          </Link>
        </h4>
        <Slider {...settings1}>
          {
            list.map((item,i)=>{
              return(
                <div className="col-12 px-1 my-1"  key={`slick-item-${i}`}>
                  <Link to={`/proveedor/${item.restaurant_slug}`}>
                  <div className="border w-100" >
                    <img alt="" className="img-fluid w-100 w-md-75 py-2" src={item.logo_url_restaurant}/>
                    <div div className="text-center mb-3">
                      <Rate
                        readOnly
                        value={GetNumericFloat(item.rating_final)}
                        allowHalf
                        size="xs"
                      />
                      <small className="small rate-item">
                        {GetNumericFloat(item.rating_final)}
                      </small>
                    </div>
                  </div>
                  </Link>
                </div>
              )
            })
          }
        </Slider>
      </div>
    </div>
  );
}

export default WidgetSuppliers;