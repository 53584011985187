import React, {Component} from 'react';
import NavBarTop from './Components/NavBarTop';
import NavTop from './Components/NavTop';
import NavBottom from './Components/NavBottom';
import Footer from './Components/Footer';
//import { Link } from 'react-router-dom';

export default class MainPrivate extends Component {
	render () {
		//let class_cont= "layout-main px-3 h-100"
		return (
			<>
				{<NavTop />}
				<div className="container-fluid p-0 box-shadow">
				</div>
				<div className="container viewport-container">
					<div className="col-12 px-0">
						{<NavBarTop />}
						{this.props.children}
					<NavBottom />
					<Footer />
					</div>
				</div>
			</>
		);
	}
}