import React, { Component, useEffect, useState } from 'react';
import { Col, Row, Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';
import Api from '../Services/Api';
import FormProcessing from '../Services/FormProcessing';


const FormCategory = (props) => {
	const { categories, loadList } = props
	const [data, setData] = useState({});
	const [form, setForm] = useState({});

	useEffect(() => {
		if(categories!=null){
			setData(categories)
			setForm(categories)
		}
	},[props.categories]);

	const handleOnChnage = async (e,info)  => {
		if(e.target && e.target.type === 'file' && typeof e.target.files[0] !== 'undefined'){
			const name = e.target.files[0].name
			const promise = await  FormProcessing.getBase64(e.target.files[0]);
			setForm({
				...data,
				...form,
				logo_product:{
					file: promise.result,
					name,
				},
			});
		}else{
			const name =  e.target ? e.target.name  : e;
			const value =  e.target ?
							 e.target.value  : info.value ? 
							 	info.value : info;
			setForm({
				...data,
				...form,
				[name]:value,
			});
		}
	}
	const handleSubmit = async (e)  => {
		e.preventDefault();
		let response = {};
		if (form.id) {
			response = await Api.adminCategoriesUpdate(form.id,form);
			alert('Actualizado de forma exitosa!');
		}else{
			response = await Api.adminCategoriesCreate(form);
			alert('Creado de forma exitosa!');
		}
		loadList()
	}
	return(
		<Form onSubmit={ (e) => handleSubmit(e) }>
			<Row form>
				<Col md={6}>
					<FormGroup>
						<Label for="categoryName">Nombre</Label>
						<Input required type="text" id="categoryName" name="name" placeholder="nombre de la categoría.." defaultValue={data.name} onChange={(e) => handleOnChnage(e)}/>
					</FormGroup>
				</Col>
				<Col md={6}>
					<FormGroup>
						<Label for="categorySlug">Slug</Label>
						<Input type="text" id="categorySlug" name="slug" placeholder="slug de la categría.." defaultValue={data.slug} onChange={(e) => handleOnChnage(e)}/>
					</FormGroup>
				</Col>
			</Row>
			
			<Row form>
				<Col md={6}>
					<Label for="categoryFile">Foto</Label>
					<FormGroup>
						<Input type="file"  id="categoryFile" name="logo_product" onChange={(e) => handleOnChnage(e)} />
						<FormText color="muted">
							Recuerda subir las fotos con las medidas necesarias.
						</FormText>
					</FormGroup>
				</Col>
				<Col md={6}>
					{
						!form.logo_product && data.logo_url  ?
						<img src="img-fluid" src={data.logo_url} height="80"/> : 
						(form.logo_product ? <img src="img-fluid" src={form.logo_product.file} height="80"/> : '')
					}
				</Col>
			</Row>
			<Button>{data.id ? 'Actualizar' : 'Crear'}</Button>
		</Form>
	);
}
export default  FormCategory;