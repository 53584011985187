import React, {Component} from 'react';

export default class View404 extends Component {
	render () {
		var data = "";
		var uri = window.location.pathname.substring(1).split('/')[0];
		if(
			uri==='auth'
			||uri==='activar'
			||uri==='reset'
			||uri==='sign'
			||uri==='enviar'
		){
			data=<p className="text-center h4">
						<span className="h1">¡Buenísimo!</span>
						<br/>
						Ya has iniciado sesión!, ya puedes votar!.
						<br/>
						<a href="/">Ir a votar</a>
					</p>
		}else{
			data=<p className="text-center h4">
						<span className="h1">404</span>
						<br/>
						Ups! La siguiente url no fue encontrada
						<br/>
							{data}
						<code>
							{}
						</code>
						<code>
							{window.location.href}
						</code>
					</p>
		}
		return (
			<>
			<div className="container-fluid h-100">
				<div className="row align-items-center justify-content-md-center h-100">
					<div className="col-12 mt-5 pt-5">
						{data}
					</div>
				</div>
			</div>
			
			</>
		);
	}
}