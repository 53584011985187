import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Icons from '../Icons/index'
import Api from '../../Services/Api'
import {GetNumeric} from '../../Services/Helpers';

const WidgetTheTopJudges = () => {

  const [data,setData] = useState({});

  useEffect(()=>{
    const run = async () => {
      const result = await Api.theTopJudgeQualification();
      if(result.data){
        setData(result.data)
      }
    }
    run()
  },[])
  
  return(
    <div className="row py-5 widget-the-top-judges">
      <div className="col-12">
        <p className="text-center h4">
          CATEGORÍAS
        </p>
        <div className="row justify-content-between justify-content-md-center">
          <div className="col-auto text-center">
            <Icons.EstrellaBronceSvg/>
            <p className="small font-weight-light text-center">
              CATADOR
              <br/>
              <small>{ data.bronze ? GetNumeric(data.bronze.cantidad) : 0} JUECES</small>
            </p>
          </div>
          <div className="col-auto text-center">
            <Icons.EstrellaPlataSvg/>
            <p className="small font-weight-light text-center">
              FOODIE
              <br/>
              <small>{data.silver ? GetNumeric(data.silver.cantidad) : 0} JUECES</small>
            </p>
          </div>
          <div className="col-auto text-center">
            <Icons.EstrellaOroSvg/>
            <p className="small font-weight-light text-center">
              GOURMET
              <br/>
              <small>{ data.gold ? GetNumeric(data.gold.cantidad) : 0} JUECES</small>
            </p>
          </div>
        </div>
        <p className="text-center">
          <Link
            to="/categorias"
            className="btn py-2 my-5 custom-btn-red custom-btn rounded-pill"
          >
            Ver categorías
				  </Link> 
        </p>
      </div>
    </div>
  )
}

export default WidgetTheTopJudges;