import React, {Component} from 'react';
import {
	Collapse,
	Navbar,
	NavbarToggler,
	Nav,
	NavItem,
	NavLink,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem 
} from 'reactstrap';
import { Link } from 'react-router-dom';

import brand from '../Assets/logo_nav.png'
import iconUserProfile from '../Assets/iconUserProfile.png'
import Session from '../../Services/Session'
import queryString from 'query-string';
import { GetForCountry }from '../../Services/Helpers';

export default class NavBarTop extends Component {
	constructor(props) {
		super(props);
		let querySearch = queryString.parse(window.location.search)
		let name = typeof querySearch.n === 'undefined' ? null : querySearch.n;
		this.toggle = this.toggle.bind(this);
		this.state = {
			isOpen: false,
			nameSearch:name
		};
		this.handleClose = this.handleClose.bind(this);
		this.toggleSearch = this.toggleSearch.bind(this);
		this.search = React.createRef();
		this.inputSearch = React.createRef();
	}
	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}
	toggleSearch(e){
		let element=this.search.current
		let input=this.inputSearch.current
		if (element.classList.contains('open')) {
			if(input.value.length>0){
				window.location.href='/juez/buscar?n='+input.value
				return null
			}
		    e.preventDefault()
		}
		element.classList.toggle("open")
	}
	handleClose(event){
		event.preventDefault();
		Session.close();
		this.setState(
			state => ({
				close: !state.close
			})
		);
		window.location.href='/';
	}
	render() {
		const link=window.location.pathname.substring(1).split('/')
		const { nameSearch } = this.state
		const mainDomain = GetForCountry().mainDomain
		const alfa2 = GetForCountry().alfa2
		
		let BtnPuntuacione = () => {return('')}
		let BtnSearch = () => {return('')}
		//if(link[0]==='restoranes' && link[1]==='zona'){
			BtnPuntuacione = () => {
				console.log('Puntuaciones')
				return (
					<Link to={"/the-top/zona/"+link[2]} className="btn-right-top pulse">
						Puntuaciones
					</Link>
				)
			}
		//}
		if(link[0]==='perfil' || link[0]==='juez' || link[0]==='mi'){
			BtnSearch = () => {
				let classContanierSearch="input-group mb-3 bg-white px-1 form-search col";
				return (
					<div 
						className={classContanierSearch+(nameSearch===null ? '' : ' open')}
						ref={this.search}
					>
						<input 
							type="text"
							className="form-control my-2"
							name="input-search"
							placeholder="Buscar.."
							aria-label="Buscar"
							aria-describedby="basic-addon1"
							id="input-search"
							ref={this.inputSearch}
							defaultValue={nameSearch}
						/>
						<label
							className="input-group-prepend"
							onClick={(e)=> {this.toggleSearch(e)}}
							htmlFor="input-search"
						>
							<span className="input-group-text mt-2 py-0 px-2">
								<i className="fas fa-search fa-2x col px-0"/>
							</span>
						</label>
					</div>
				)
			}
		}
		const Brand = () => {
			return (<a className="navbar-brand" href={mainDomain}>
				<img 
				alt="Brand"
				className="img-fluid header-logo"
				src={GetForCountry().brand}
				width="229"
				height="101"
				/>
			</a>)
		}
		const NavItemJuez = () => {
			return(
				<>
					<NavItem>
						<NavLink href={mainDomain}>
							INICIO
						</NavLink>
					</NavItem>
					{
						alfa2 === 'CL' ?
						<UncontrolledDropdown nav inNavbar>
							<DropdownToggle nav caret>
								EVENTOS THE TOP
							</DropdownToggle>
							<DropdownMenu right>
								<DropdownItem tag="a" href={mainDomain+"/the-top-pizza/"}>
									THE TOP PIZZA 2019
								</DropdownItem>
								<DropdownItem tag="a" href={mainDomain+"/22298-2/"}>
									THE TOP PIZZA NACIONAL 2020
								</DropdownItem>
								<DropdownItem tag="a" href={mainDomain+"/the-top-burguer/"}>
									THE TOP BURGER 2019
								</DropdownItem>
								<DropdownItem tag="a" href={mainDomain+"/the-top-nacional/"}>
									THE TOP BURGER NACIONAL 2020
								</DropdownItem>
								<DropdownItem tag="a" href={mainDomain+"/the-top-sushi/"}>
									THE TOP SUSHI
								</DropdownItem>
								<DropdownItem tag="a" href={mainDomain+"/the-top-sandwich/"}>
									THE TOP SANDWICH
								</DropdownItem>
							</DropdownMenu>
						</UncontrolledDropdown> :
						null
					}
					<NavItem>
						<NavLink href={"/juez/"}>
							THE TOP JUEZ
						</NavLink>
					</NavItem>				
					<NavItem>
						<NavLink href={mainDomain+"/quieres-inscribir-tu-restoran/"}>
							INSCRIBE TU RESTORÁN
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink href={mainDomain+"/blogs"}>
							BLOG
						</NavLink>
					</NavItem>
					{/*
						alfa2 === 'CL' ?
						<NavItem>
							<NavLink href={mainDomain+"/quienes-somos/"}>
								QUIENES SOMOS
							</NavLink>
						</NavItem>:
						null
					*/}
					<NavItem>
						<NavLink href={mainDomain+"/contactenos/"}>
							CONTÁCTANOS
						</NavLink>
					</NavItem>
				</>
			) 
		}
		const NavItemdmin = () => {
			return(
				<>
					<NavItem>
						<Link to={"/"} className="nav-link">
							Dashboard
						</Link>
					</NavItem>
					<NavItem>
						<Link to={"/admin/productos"} className="nav-link">
							Productos
						</Link>
					</NavItem>
                    <NavItem>
						<Link to={"/users"} className="nav-link">
							Usuarios
						</Link>
					</NavItem>
					<NavItem>
						<Link to={"/admin/categorias"} className="nav-link">
							Categorías
						</Link>
					</NavItem>
					<NavItem>
						<Link to={"/admin/proveedores"} className="nav-link">
							Proveedores
						</Link>
					</NavItem>
					<NavItem>
						<Link to={"/admin/eventos"} className="nav-link">
							eventos
						</Link>
					</NavItem>
				</>
			)
		}
		const Menu = () => {
			return (
			<>
				<NavbarToggler onClick={this.toggle} />
				<Collapse isOpen={this.state.isOpen} navbar className="p-2">
					<Nav className="ml-auto" navbar>
						{ Session.isAdmin() ? <NavItemdmin/>: <NavItemJuez/>}
						<UncontrolledDropdown nav inNavbar>
							<DropdownToggle nav>
								<img src={iconUserProfile}/>
							</DropdownToggle>
							<DropdownMenu right>
								<Link to={"/mi/perfil/"+Session.getUserName()}className="dropdown-item">
									MI PERFIL
								</Link>
								<DropdownItem onClick={this.handleClose}>
									CERRAR SESIÓN
								</DropdownItem>
							</DropdownMenu>
						</UncontrolledDropdown>
					</Nav>
				</Collapse>
			</>
			)
		}
		const LinkMaps = () => {
			return (
				<a
					href="https://thetop.cl/wp-content/uploads/2020/11/TheTopSushi-Participantes-Catalogo.pdf"
					target="_blank"
					style={{width: '41px'}}
				>
				<div style={{
					width: '41px',
					textAlign: 'right',
					position: 'absolute',
					right: '0px',
					top: '1rem',
				}} className="pr-3">
					<small>
					<small style={{
						position:"absolute",
						right:'39px',
						top:'-0.3rem;',
					}}>
					DESCARGA
					<br/>
					PDF
					</small>
					</small>
					<i className="fas fa-file-pdf fa-2x text-danger"/>
				</div>
				</a>
			)
		}
		const OptionsSticky = () =>{
			return(
				<div className={"row pb-1 pt-2 sticky-top "+ClassHiddenSmXs} style={{top:"67px"}}>
					<div className="col text-left px-1">
						<div className="btn-group" role="group" aria-label="Basic example">
							<Link to={"/the-top"} className="btn custom-btn-red py-2">
								Puntuaciones
							</Link>
							{
								alfa2 === 'CL' ?
								<Link to={"/"} className="btn custom-btn-red py-2">
									Regiones
								</Link>
								: null
							}
							<Link to={"/juez"} className="btn custom-btn-red py-2">
								Top Juez
							</Link>
						</div>
					</div>
				</div>
			)
		}
		const ClassNav ="px-md-4 px-md-0 navbar-top sticky-top px-1"
		const ClassHiddenLgXl = "d-md-none d-lg-none d-xl-none"
		const ClassHiddenSmXs = "d-none d-sm-none d-md-flex"
		return (
			<>
				<Navbar color="white" className={ClassNav+' '+ClassHiddenLgXl} expand="md" style={{zIndex:"1021"}}>
					<Menu/>
					<Brand/>
					{/*<LinkMaps/>*/}
					{<div style={{width:'50px'}}></div>}
				</Navbar>
				<Navbar color="white" className={ClassNav+' '+ClassHiddenSmXs} expand="md" style={{zIndex:"1022",marginBottom:'29px'}}>
					<Brand/>
					<Menu/>
				</Navbar>
				<div className={"row pb-1 pt-0 sticky-top d-flex justify-content-between "+ClassHiddenLgXl} style={{top:"67px"}}>
					<div className="col-2 pl-0 pr-1">
						<span onClick={(e)=> {window.history.back()}}>
							<i className="fas fa-chevron-left fa-3x btn-back"/>
						</span>
					</div>
					<div className="col-10 pl-1 pr-0 text-right">
						<BtnPuntuacione/>
						<BtnSearch/>
					</div>
					{/*
					<div className="col text-right px-1">
						<Link to={"/the-top"} className="btn custom-btn-red py-2">
							Ver puntuacíon
						</Link>
					</div>
					*/}
					{ Session.isAdmin() ? null : <OptionsSticky/>}
				</div>
				
			</>
		);
	}
}