import React, { useRef, useState } from 'react';
import  InputFileImg from '../Layout/Components/InputFileImg'
import  FormProcessing from '../Services/FormProcessing'
import SweetAlert from 'sweetalert2-react';

import Api from '../Services/Api';

const VoteEdit = (props) => {
	const form = useRef(null);
	const [ resetForm, setResetForm ] = useState(false)
	const [ inputs , setInputs ] = useState({...props.data})
	const [ alert , setAlert ] = useState({
		show:false,
		type:'success',
		txt:''
	})
	const handleSubmit = async (e) => {
		e.preventDefault()
		let data = await FormProcessing.toObject(form.current)
		let response = await Api.updateVoting(inputs.id,data)
		if (response.success) {
			props.updateVoting(response.voting)
			setAlert({
				show:true,
				type:'success',
				txt:'Voto actualizado'
			})
		}else if(response.enable_validations===true){
			FormProcessing.validate(form.current,response)
		}
	}
	const handleInput = (e) =>{
		const update = {
			...inputs,
			[e.target.name]: e.target.value
		}
		setInputs(update);
	}
	return(
		<form 
				className={"col-md-8"}
				onSubmit={handleSubmit} ref={form} 
				>
					<SweetAlert
						show={alert.show}
						title="Wow!"
						type={alert.type}
						text={alert.txt}
						onConfirm={(e) => setAlert({...alert,show:false,txt:''})}
					/>
					<button class="btn btn-outline-danger btn-sm rounded-pill px-2 pt-1 pb-0 float-right" type="button" onClick={props.shwoFrom}>
						<i class="fas fa-close"></i>
					</button>
					<label>
							Cantidad de estrellas *
					</label>
					<br/>
					<div className="rate px-0">
					    <input type="radio" id="star5" name="rating" value="5" checked={parseInt(inputs.rating) === 5}  onChange={handleInput}/>
					    <label htmlFor="star5" title="text">5 stars</label>
					    <input type="radio" id="star4" name="rating" value="4" checked={parseInt(inputs.rating) === 4}  onChange={handleInput}/>
					    <label htmlFor="star4" title="text">4 stars</label>
					    <input type="radio" id="star3" name="rating" value="3" checked={parseInt(inputs.rating) === 3}  onChange={handleInput}/>
					    <label htmlFor="star3" title="text">3 stars</label>
					    <input type="radio" id="star2" name="rating" value="2" checked={parseInt(inputs.rating) === 2}  onChange={handleInput}/>
					    <label htmlFor="star2" title="text">2 stars</label>
					    <input type="radio" id="star1" name="rating" value="1" checked={parseInt(inputs.rating) === 1}  onChange={handleInput}/>
					    <label htmlFor="star1" title="text">1 star</label>
					</div>
					<br/>
					<br/>
					<br/>
					<div className="form-group mt-2">
						<label>
							Número de boleta *
						</label>
						<input name="invoice_number" className="form-control" value={inputs.invoice_number} onChange={handleInput}/>
					</div>
					<div className="form-group mt-4 pt-2">
						<label>
							Comentario (opcional)
						</label>
						<textarea className="form-control" rows="5" name="description" value={inputs.description} onChange={handleInput}>
						</textarea>
					</div>
					<div className="form-group">
						<InputFileImg
							name="photo"
							iconClass="img text-center d-block"
							reset={resetForm}
							srcInput={inputs.photo_url}
						/>
					</div>

					<div className="form-group">
						<button
							className="btn btn-success px-5 btn-block"
						>
							<span className="px-5">
								<span className="h3">
								Actualizar
								</span>
							</span>
						</button>
					</div>
				</form>
	);
}
export default VoteEdit;