import React from 'react';
import { Link } from 'react-router-dom';

const BreadCrumbsClassic = (prosp) => {
  return(
    <div className="row">
      <nav className="w-100">
        <ol className="breadcrumb rounded-0 mb-0">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>
          {
            prosp.items ? prosp.items.map((item,i)=>{
              return(
                <li className="breadcrumb-item"><Link to={item.link}>{item.name}</Link></li>
              )
            }) : null
          }
          {/* <li className="breadcrumb-item active" aria-current="page"><a href="/evento/12">evento</a></li>
          <li className="breadcrumb-item"><a href="/proveedor/mozzafiato">Restaurant</a></li> */}
        </ol>
      </nav>
    </div>
  );
}

export default BreadCrumbsClassic;
