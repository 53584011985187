import React, {Component} from 'react';

export default class PartnerCL extends Component {
	render() {
		return (
			<div className="col-12 pt-5 text-center">
				<h3 className="mb-5">
					Partners oficiales
				</h3>
				<div className="row justify-content-center">
					{/*<div className="col-6">
						<img 
							alt="Icon"
							className="img-fluid mt-3"
							src={LogoPedidosYa}
							scale="0"
							width="250"
							height="50"
						/>
					</div>
					{<img 
						alt="Icon"
						className="img-fluid px-5 mt-3"
						src={LogoTravel}
						scale="0"
						width="330"
						height="50"
					/>}
					<div className="col-6">
						<img 
							alt="Icon"
							className="img-fluid mt-3"
							src="https://thetop.cl/wp-content/uploads/2020/01/cmr.png"
							scale="0"
							width="250"
							height="50"
						/>
					</div>*/}
					{/*<div className="col">
					    <img src="https://site.thetop.cl/wp-content/uploads/2020/03/fo-5.svg" alt=""  width="200" height="150"/>
					</div>
					<div className="col">
					    <img src="https://site.thetop.cl/wp-content/uploads/2020/03/fo-2.svg" alt=""  width="110" height="150"/>
					</div>
					<div className="col">
					    <img src="https://site.thetop.cl/wp-content/uploads/2020/03/fo-3.svg" alt=""  width="100" height="150"/>
					</div>*/}
					<div className="col">
					    <img src="https://site.thetop.cl/wp-content/uploads/2022/05/banco-falabella.png" alt=""  height="60" style={{margin:'25px 0px'}}/>
					</div>
					<div className="col">
					    <img src="https://site.thetop.cl/wp-content/uploads/2020/03/fo-4.svg" alt=""  width="110" height="150"/>
					</div>
				</div>
			</div>
		)
	}
}