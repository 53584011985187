import React, { Component, useState, useEffect, useRef } from "react";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import { DateTime } from "react-datetime-bootstrap";
import Api from "../Services/Api";

const FormEvents = (props) => {
    const { event, loadList } = props;
    const [data, setData] = useState({});
    const [form, setForm] = useState({});
    const [isChecked, setIsChecked] = useState({
        nombre: false,
        "nombre-Oblig": false,
        rut: false,
        "rut-Oblig": false,
        fecha_Nac: false,
        "fecha_Nac-Oblig": false,
    });
    const [eventConfig, setEventConfig] = useState({});


    const getEventData = async (event) => {
        let res = await Api.getParamsEventsAdmin({id: event.id});
        const checked = {
            nombre: res.nombre === 1 || res.nombre === 2 ? true : false,
            "nombre-Oblig": res.nombre == 2 ? true : false,
            rut: res.rut === 1 || res.rut === 2 ? true : false,
            "rut-Oblig": res.rut === 2 ? true : false,
            fecha_Nac: res.fecha_Nacimiento === 1 || res.fecha_Nacimiento === 2 ? true : false,
            "fecha_Nac-Oblig": res.fecha_Nacimiento === 2 ? true : false,
        }
        setIsChecked(checked);
        setEventConfig(res);
    }

    useEffect(() => {
        if (event != null) {
        setData(event);
        setForm(event);
        getEventData(event);
        }
    }, [props.event]);

  const selectActive = { selected: "none" };

  const handleOnChnage = (e, info) => {
    const name = e.target ? e.target.name : e;
    const value = e.target ? e.target.value : info.value ? info.value : info;
    setForm({
      ...data,
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let response = {};
    let res = getCheked();
    form["isChecked"] = res;
    if (form.id) {
      response = await Api.adminEventsUpdate(form.id, form);
      alert("Actualizado de forma exitosa!");
    } else {
      response = await Api.adminEventsCreate(form);
      alert("Creado de forma exitosa!");
    }
    loadList();
  };

  const getCheked = () => {
    let res = {
      nombre: 0,
      rut: 0,
      fecha_Nacimiento: 0,
    };
    if (isChecked.nombre) {
      res.nombre = 1;
      if (isChecked["nombre-Oblig"]) {
        res.nombre = 2;
      }
    }
    if (isChecked.rut) {
      res.rut = 1;
      if (isChecked["rut-Oblig"]) {
        res.rut = 2;
      }
    }
    if (isChecked.fecha_Nac) {
      res.fecha_Nacimiento = 1;
      if (isChecked["fecha_Nac-Oblig"]) {
        res.fecha_Nacimiento = 2;
      }
    }
    return res;
  };

  const handleCheckboxChange = (e) => {
    setIsChecked({
      ...isChecked,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      <FormGroup>
        <Label for="eventName">Nombre</Label>
        <Input
          required
          type="text"
          id="eventName"
          name="name"
          placeholder="nombre del evento.."
          defaultValue={data.name}
          onChange={(e) => handleOnChnage(e)}
        />
      </FormGroup>
      <Row form>
        <Col md={6}>
          <FormGroup>
            <Label for="eventStart">Inicio</Label>
            <DateTime
              required
              id="eventStart"
              name="start"
              pickerOptions={{
                format: "YYYY-MM-DD HH:mm",
              }}
              placeholder="fecha de inicio.."
              value={data.start}
              onChange={(e, date) => handleOnChnage("start", date)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="eventEnd">Fin</Label>
            <DateTime
              required
              id="eventEnd"
              name="end"
              pickerOptions={{
                format: "YYYY-MM-DD HH:mm",
              }}
              placeholder="fecha de fin.."
              value={data.end}
              onChange={(e, date) => handleOnChnage("end", date)}
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Label for="eventActive">Estado</Label>
        <Input
          required
          type="select"
          id="eventActive"
          name="active"
          value={form.active}
          onChange={(e) => handleOnChnage(e)}
        >
          <option value="">Selecciona el estado</option>
          <option value={1}>Activo</option>
          <option value={0}>Inactivo</option>
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="eventType">Tipo</Label>
        <Input
          required
          type="select"
          id="eventType"
          name="type"
          value={form.type}
          onChange={(e) => handleOnChnage(e)}
        >
          <option value={1}>Zonas</option>
          <option value={2}>Categorías</option>
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="eventDescription">Descripción(Acepta tags html)</Label>
        <Input
          type="textarea"
          id="eventDescription"
          name="description"
          defaultValue={data.description}
          onChange={(e) => handleOnChnage(e)}
        />
      </FormGroup>

      <Label for="eventDescription">Campos personalizados</Label>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          justifyItems: "center",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <label
          style={{
            marginLeft: "10px",
            width: "140px",
          }}
        >
          Nombre
        </label>
        <input
          name="nombre"
          type="checkbox"
          checked={isChecked.nombre}
          onChange={handleCheckboxChange}
          style={{ marginLeft: "10px", marginTop: "-10px" }}
        />

        <label>
          <input
            name="nombre-Oblig"
            type="checkbox"
            checked={isChecked["nombre-Oblig"]}
            onChange={handleCheckboxChange}
            style={{ marginLeft: "10px" }}
            disabled={!isChecked["nombre"]}
          />
        <span className="ml-1">Requerido</span>
        </label>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
        }}
      >
        <label
          style={{
            marginLeft: "10px",
            width: "140px",
          }}
        >
          Rut
        </label>
        <input
          name="rut"
          type="checkbox"
          checked={isChecked.rut}
          onChange={handleCheckboxChange}
          style={{ marginLeft: "10px", marginTop: "-10px" }}
        />

        <label>
          <input
            name="rut-Oblig"
            type="checkbox"
            checked={isChecked["rut-Oblig"]}
            onChange={handleCheckboxChange}
            style={{ marginLeft: "10px" }}
            disabled={!isChecked["rut"]}
          />
            <span className="ml-1">Requerido</span>
        </label>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          marginBottom: "10px",
        }}
      >
        <label
          style={{
            marginLeft: "10px",
            width: "140px",
          }}
        >
          Fecha de Nacimiento
        </label>
        <input
          name="fecha_Nac"
          type="checkbox"
          checked={isChecked.fecha_Nac}
          onChange={handleCheckboxChange}
          style={{ marginLeft: "10px", marginTop: "-10px" }}
        />

        <label>
            <input
                name="fecha_Nac-Oblig"
                type="checkbox"
                checked={isChecked["fecha_Nac-Oblig"]}
                onChange={handleCheckboxChange}
                style={{ marginLeft: "10px" }}
                disabled={!isChecked["fecha_Nac"]}
            />
            <span className="ml-1">Requerido</span>
        </label>
      </div>
      <Button>{data.id ? "Actualizar" : "Crear"}</Button>
    </Form>
  );
};
export default FormEvents;
