import React, { useState } from 'react';
const TableGeneral = ( props ) => {
	const {rowsWithColumns, rowHeader, tableClassName, tableRowClassName, tableColumnClassName} = props
	console.log('value csv',props)
	const renderTableHeader = (row) => {
		if (row && row.map) {
			return (
				<thead>
					<tr>
					{
						row.map((column, i) => (
							<th
							key={`new-header-${i}`}
							>
								{column}
							</th>
						))
					}
					</tr>
				</thead>
			);
		}
	};

	const renderTableBody = (rows) => {
		if (rows && rows.map) {
			return (
				<tbody>
				{
					rows.map((row, rowIdx) => (
						<tr className={tableRowClassName} key={`new-tr-${rowIdx}`}>
						{
							row.map && row.map((column, colIdx) => (
								<td
								className={tableColumnClassName}
								key={`new-col-${colIdx}`}
								>
									{column}
								</td>
							))
						}
						</tr>
					))
				}
				</tbody>
			);
		}
	};

	return (
		<table className={`csv-html-table ${tableClassName}`}>
			{renderTableHeader(rowHeader)}
			{renderTableBody(rowsWithColumns)}
		</table>
	);
};
export default TableGeneral;
