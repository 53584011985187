import React, {Component} from 'react';
export default class PartnerPE extends Component {
	render() {
		return (
			<div className="col-12 pt-5 text-center d-none">
				<h3 className="mb-5">
					Partner oficiales
				</h3>
				<div className="row justify-content-center">

				</div>
			</div>
		)
	}
}