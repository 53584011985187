import React, { useEffect, useState } from 'react';
import Api from '../../Services/Api';
import {GetNumeric} from '../../Services/Helpers';

const MainQuantities = () => {
  const [ info, setInfo ] = useState({
    judges:{cantidad:0},
    events:{cantidad:0},
    votings:{cantidad:0},
    city:{cantidad:0},
    vendors:{cantidad:0},
  });
  useEffect(()=>{
    const run = async () => {
      let response = await Api.theTopJudgeThermometer({})
      setInfo({...info,...response});
    }
    run();
  },[]);

  return(
    <div className="row py-5 text-center justify-content-between justify-content-md-center border-bottom text-black main-quantities">
      <div className="col-auto border-right">
        <h4 className="font-weight-light">{GetNumeric(info.vendors.cantidad)}</h4>
        <p className="small font-weight-light text-dark">
          RESTAURANTS
          <br/>
          MARCAS
        </p>
      </div>
      <div className="col-auto border-right">
        <h4 className="font-weight-light">{GetNumeric(info.votings.cantidad)}</h4>
        <p className="small font-weight-light">
          REVIEWS
        </p>
      </div>
      <div className="col-auto border-right">
        <h4 className="font-weight-light">{GetNumeric(info.judges.cantidad)}</h4>
        <p className="small font-weight-light">
          PERFILES
        </p>
      </div>
      <div className="col-auto border-right">
        <h4 className="font-weight-light">{12}</h4>
        <p className="small font-weight-light">
          CIUDADES
        </p>
      </div>
    </div>
  );
}
export default MainQuantities;