import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Session from '../Services/Session';
import Api from '../Services/Api';
import { GetCategoryUser }from '../Services/Helpers';
import UserImg from '../Layout/Assets/user.png';
import {AvatarsList} from '../VoteHere/Avatars';

export default class Profile extends Component {
	constructor(props) {
		super(props);
		let username_link = this.props.match.params.user_name
		this.state = {
			main: false,
			infoUser:Session.getInfoUser(),
			infoLoad:0,
			notFound:0,
			loadFollowUser:false,
			count_voting:0,
			count_followers:0,
			count_followed:0,
			is_followed:null,
			loadingFollowUser:false,
			loadingStopFollowing:false,
			get_username:( username_link === undefined ? Session.getUserName() : username_link),
		};
	}
	componentDidMount() {
		window.scrollTo(0,0)
		this.loadProfile(this.state.get_username)
	}
	componentDidUpdate(prevProps){
		const {user_name} = this.props.match.params
		if (user_name !== prevProps.match.params.user_name) {
			this.setState({
				infoLoad:0,
				notFound:0,
			})
			this.loadProfile(user_name)
		}
	}
	async loadProfile(get_username){
		let publicApi = Session.isAuth() ? false : true;
		let response = await Api.profile(get_username,publicApi)
		if(typeof response.message!=='undefined'){
			this.setState({
				notFound:response.message
			})
		}else{
			this.setState({
				infoLoad:response,
				count_voting:response.count_voting,
				count_followers:response.count_followers,
				count_followed:response.count_followed,
				is_followed:response.is_followed,
				get_username:get_username
			})
		}
	}
	async followUser(e,get_username){
		this.setState({
			loadingFollowUser:true,
		})
		let response = await Api.followUser(get_username)
		if(typeof response.error!=='undefined'){
			this.setState({
				loadingFollowUser:false,
			})
		}else{
			this.setState({
				loadingFollowUser:false,
				count_followers:response.count_followers,
				is_followed:1
			})
		}
	}
	async stopFollowing(e,get_username){
		this.setState({
			loadingStopFollowing:true
		})
		let response = await Api.stopFollowing(get_username)
		if(typeof response.error!=='undefined'){
			this.setState({
				loadingStopFollowing:false
			})
		}else{
			this.setState({
				loadingStopFollowing:false,
				count_followers:response.count_followers,
				is_followed:0
			})
		}
	}
	render() {
		const {
			infoUser,
			infoLoad,
			get_username,
			notFound,
			count_voting,
			count_followers,
			count_followed,
			is_followed,
			loadingFollowUser,
			loadingStopFollowing
		} = this.state
		const BtnAction = () => {
			if(get_username === infoUser.username){
				return (
					<Link to="/perfil/editar" className="btn custom-btn-red">
						Editar perfil
					</Link>
				)
			}
			if(is_followed === 1){
				return (
					<button
						className="btn custom-btn-red"
						onClick={(e) => this.stopFollowing(e,get_username)}
						disabled={loadingStopFollowing}
					>
						Dejar de Seguir <i className="fas fa-close"/>
					</button>
				)
			}else{
				return (
					<button
						className="btn custom-btn-red"
						onClick={(e) => this.followUser(e,get_username)}
						disabled={loadingFollowUser}
					>
						Seguir <i className="fas fa-user-plus"/>
					</button>
				)
			}
		}
		const Votings = () => {
			let VotingsHtml = []
			typeof infoLoad.voting === 'object' && infoLoad.voting.length > 0 ? 
				infoLoad.voting.map(function(v, index) {
					//v.product.logo_url
					//src={v.product.logo_url}
					VotingsHtml.push(
						<div
							className="col-4 px-1 py-1"
							key={index+1}
						>
							<Link to={"/juez/votacion/"+v.id}>
							{ v.photo_url!=null ?
								<img 
									src={v.photo_url}
									width="100%"
									alt="Foto de Productos"
									className="img-responsive h-100"
									style={{
										objectFit:'cover',
									}}
								/>
								:
								<img 
									
									src={v.product.logo_url}
									width="100%"
									alt="Foto de Productos"
									className="img-responsive h-100"
									style={{
										objectFit:'cover',
									}}
								/>
							}
							<img 
							alt=""
							className="img-fluid"
							src={v.restaurant.logo_url}
							style={{
								"position": "absolute",
								"width": "45px",
								"left": "-3.3px",
								"top": "-3.3px",
								"background":"white none repeat scroll 0% 0%",
								//"border": "1px solid  #dee2e6",
								"borderRadius": "50%",
								"padding": "5px",
							}}/>
							</Link>
						</div>
					)
					return true
				},VotingsHtml)
			:
				VotingsHtml.push(
				<div className="col-12 px-1 py-5">
					<i className="far fa-frown fa-3x"></i>
					<h3 className="text-center">Sin votos</h3>
				</div>
				)
			return VotingsHtml;
		}
		const ProfileDetail = () => {
			return (
			<>
				<div className="bg-light col py-5 text-center mt-negative-4">
				 	{ 
				 		get_username === infoUser.username ?
						<h3 className="mt-4">Mi Perfil</h3>:
						<h3 className="mt-4">Perfil</h3>
					}
					<div className="row justify-content-center">
					<div className="col-6">
					<img 
						src={infoLoad.avatar_selected == null ? (infoLoad.avatar_url == null ? UserImg : infoLoad.avatar_url) : AvatarsList[infoLoad.avatar_selected]}
						width="125"
						height="125"
						alt="Foto de perfil"
						className="img-responsive rounded-circle"
						style={{objectFit: 'cover',}}
					/>
					<GetCategoryUser className="aling-bottom" countVoting={count_voting} />
					</div>
					</div>
					<h4 className="">
						{
							typeof infoLoad.full_name !== 'undefined' ?
									infoLoad.full_name.length === 1 ?
										infoLoad.username :
											infoLoad.full_name : infoLoad.username
						}
					</h4>
					<p>
						<small>
						{infoLoad.username}
						</small>
					</p>
					<BtnAction/>
				</div>
				<div className="col-12 text-center py-3">
					<div className="row">
						<div className="col">
							<h1>
								{count_voting}
							</h1>
							Votaciones
						</div>
						<div className="col">
							<Link to={"/seguidores/de/"+infoLoad.username }>
								<h1>
									{count_followers}
								</h1>
								Seguidores
							</Link>
						</div>
						<div className="col">
							<Link to={"/seguidos/por/"+infoLoad.username }>
								<h1>
									{count_followed}
								</h1>
								Seguidos
							</Link>
						</div>
					</div>
				</div>
				<div className="col-12 text-center">
					<div className="row">
						<div className="col-12 mb-4">
							<small className="h5">votaciones</small>
							<hr className="my-0"/>
							<div className="px-5 w-25 py-1 bg-danger m-auto">
							</div>
						</div>
						<Votings/>
					</div>
				</div>
			</>
			)
		}
		return (
			<div className="row">
				{
					typeof infoLoad === 'object' && typeof notFound === 'number' ?
					<ProfileDetail/> :
					<div className="bg-light col py-5 text-center mt-negative-4">
						<h5 className="mt-4 py-5">
							{
								typeof notFound === 'number' ? 
								<>
									<i className="fas fa-cog fa-spin fa-2x"/>
									<br/>
									Cargando Perfil...
								</>
								:
								<>
									<i className="far fa-frown fa-3x"></i>
									<br/>
									{notFound}
								</>
							}
						</h5>
					</div>
				}
			</div>
		)
	}
}