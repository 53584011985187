
import React, {Component} from 'react';
import Session from '../Services/Session';
import Api from '../Services/Api';
import Paginate from '../Layout/Components/Paginate'

import  InputFileImg from '../Layout/Components/InputFileImg'
import { Spinner } from 'reactstrap'
import FormProcessing from '../Services/FormProcessing'
import { DateTime } from 'react-datetime-bootstrap';
import UserImg from '../Layout/Assets/user.png';
import { CSVLink, CSVDownload } from "react-csv";
import { Link } from 'react-router-dom';

export default class UsersVotes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			votings:[],
			csvData:[],
            user_id: this.props.match.params.id,
			headers:[
			  	{ label: "Voto id", key:"voto_id" },
				{ label: "Proveedor", key:"proveedor" },
				{ label: "Producto", key:"producto" },
				{ label: "Correo", key:"email" },
				{ label: "Valoración", key:"valoracion" },
				{ label: "Numero Boleta", key:"numero_boleta" },
				{ label: "Comentario", key:"comentario" },
				{ label: "Url foto", key:"foto" },
				{ label: "Hora de voto CL", key:"hora_de_votacion_santiago" },
				{ label: "Ip", key:"ip" },
				{ label: "Dispositivo", key:"dispositivo" },
				{ label: "País", key:"country" },
			],
			pagination:null,
		};
		this.getDetails = this.getDetails.bind(this);
        this.blockVote = this.blockVote.bind(this);
        this.unblockVote = this.unblockVote.bind(this);
	}
	UNSAFE_componentWillMount() {
		window.scrollTo(0,0)
		this.getDetails()
	}
	async getDetails(page={}){
        console.log(page)
		let response = await Api.getVotesForUser(this.props.match.params.id, page)
        console.log("aca", response)
		const pagination = {
			last_page:response.last_page,
			current_page:response.current_page,
			per_page:response.per_page
		}
        console.log("pagin: ", pagination)
		this.setState({
			votings:response.data,
			pagination
		});
	}

    async blockVote(id){
        const { pagination  } = this.state
        if (window.confirm("¿Estas seguro de bloquear este voto?")) {
            let response = await Api.blockVote(id)
            this.getDetails()
        }
    }

    async unblockVote(id){
        const { pagination  } = this.state
        if (window.confirm("¿Estas seguro de desbloquear este voto?")) {
            let response = await Api.unblockVote(id)
            this.getDetails()
        }
    }

    async handleUpdate(event) {

    }

	render() {
		const { votings, csvData, headers, pagination, user_id } = this.state
        const { unblockVote, blockVote, handleUpdate } = this

		return(
            <>

                <div className="row">
                    <div className="col-md-12">
                        <Link to={`/users`}>Volver</Link>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-12">
                        <table className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th scope="col" colspan="6" className="text-center">
                                        Votos
                                    </th>
                                </tr>
                            </thead>
                            <thead>
                                <tr>
                                    <th scope="col">ID VOTO</th>
                                    <th>Votador</th>
                                    <th scope="col">Rating</th>
                                    <th>Descripcion</th>
                                </tr>
                            </thead>
                            <tbody>
                                {	votings.length > 0 ? 
                                    votings.map(function callback(vote, index, array) {
                                        return(
                                            <tr  valign="middle">
                                                <td scope="row">{vote.id}</td>
                                                <td>{vote.user.name}</td>
                                                <td>{vote.rating}</td>
                                                <td>{vote.description}</td>
                                                <td>
                                                    {
                                                        vote.deleted_at ? <>
                                                            <button className="btn btn-primary btn-sm" type="submit" onClick={()=>unblockVote(vote.id)}>
                                                                Desbloquear voto
                                                            </button>
                                                        </> : <>
                                                            <button className="btn btn-danger btn-sm" type="submit" onClick={()=>blockVote(vote.id)}>
                                                                Bloquear voto
                                                            </button>
                                                        </>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <th scope="row" colspan="6">Sin votos</th>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 d-flex justify-content-center fixed-bottom pb-5"
                                style={{
                                    marginBottom: '5rem',
                                }}>
                            {
                                pagination != null ?
                                <Paginate 
                                    last_page={pagination.last_page}
                                    current_page={pagination.current_page} 
                                    per_page={pagination.per_page}
                                    loadList={this.getDetails}
                                    classNamew="border border-bottom-0 bg-white px-5 pt-4"
                                />
                                :null
                            }
                    </div>
                </div>
            </>

		);
	}
}