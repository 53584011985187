import React from 'react';
import NavMain from './Components/NavMain'
import NavBottom from '../Components/NavBottom';
import Footer from '../Components/Footer';
const UserLayout = (props) => {
  return(<>
    <div className="container-fluid p-0 box-shadow">
    </div>
    <div className="container viewport-container">
      <div className="col-12 px-0" style={{marginTop:'-5px'}}>
        {<NavMain />}
        {props.children}
        <NavBottom />
        <Footer />
      </div>
    </div>
  </>);
}
export default UserLayout