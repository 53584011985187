import React, { useEffect, useState } from 'react';
import { Button } from 'rsuite';
import { useHistory, Link } from 'react-router-dom';

import Icons from '../../Components/Icons'
import Api from '../../Services/Api';

const WidgetCategory  = (props) => {
  const history = useHistory();
  const [ list, setList ] = useState([]);
  useEffect(()=>{
    const run = async () => {
      let response = await Api.getEventsListing({lot:15});
      setList(response.data);
    }
    run()
  },[]);

  return(
    <div className="row mt-4">
      <div className="col-12">
        <div className="row" style={{
          marginRight: '-0.25rem',
          marginLeft: '-0.25rem',
        }}>
        {
          list.map((item)=>{
            let IconCategory=()=>null
            switch (item.name) {
              case 'Tentaciones':
                IconCategory=()=><Icons.SalverSvg/>
                break;
              case 'Sushi':
                IconCategory=()=><Icons.SushiSvg/>
                break;
              case 'Sandwich':
                IconCategory=()=><Icons.SandwichSvg/>
                break;
              case 'Pizza':
                IconCategory=()=><Icons.PizzaSvg/>
                break;
              case 'Ice Cream':
                IconCategory=()=><Icons.IceSvg/>
                break;
              case 'Empanadas':
                IconCategory=()=><Icons.PastrySvg/>
                break;
              case 'Burger':
                IconCategory=()=><Icons.BurgerSvg/>
                break;
              case 'Beer':
                IconCategory=()=><Icons.BeerSvg/>
                break;
              default:
                IconCategory=()=><Icons.SalverSvg/>
                break;
            }
            return(
              <div className={`px-1 ${props.elementClassName ?? 'col-6 col-md-3'}`}>
                <Button 
                  className="custom-btn-red w-100 mb-3 d-flex justify-content-between align-items-cente"
                  onClick={()=>history.push(`/evento/${item.name}`)}
                >
                  <IconCategory/>
                  <span style={{
                    maxWidth: '125px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}>
                    {item.name}
                  </span>
                </Button>
              </div>
            )
          })
        }
        </div>
      </div>
      {/*<p className="col-12 text-center">
        <Link to="/evento/Tentaciones" className="btn btn-link">
          ver más 
        </Link>
      </p>*/}
    </div>
  );
}

export default WidgetCategory;