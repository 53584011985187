import { BACK_URL, BACK_TOKEN } from '../config';
import Session from './Session';
import { GetForCountry } from './Helpers';

class Fetch {
	constructor () {
		this.endpoint = ''
		this.headers = {
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Token-Api': BACK_TOKEN,
				'Token-User': Session.getTokenUser(),
				'Status-User': Session.getStatusUser(),
				'Type-Profile-User': Session.getTypeProfileUser(),
				'Country-Alfa-2': GetForCountry().alfa2,
			}
		}
	}
	async get(endpoint,data={}) {
		let qs = this.encodeQueryString(data);
		this.endpoint = endpoint+ ((qs !== '') ? ('?' + qs) : '')
		this.headers.method = 'GET'
		delete this.headers['body'];
		return this.send()
	}
	async post(endpoint,data={}) {
		this.endpoint = endpoint
		this.headers.method = 'POST'
		this.headers.body = this.fromData(data)
		return this.send()
	}
	async put(endpoint,data={}) {
		this.endpoint = endpoint
		this.headers.method = 'PUT'
		this.headers.body = this.fromData(data)
		return this.send()
	}
	async delete(endpoint,data={}) {
		this.endpoint = endpoint
		this.headers.method = 'DELETE'
		this.headers.body = JSON.stringify(data)
		return this.send()
	}
	async send() {
		let result = {}
		let endpoint = BACK_URL + this.endpoint
		try {
			let response = await fetch(endpoint, this.headers)
				
			result = await response.json()
			if(response.status===422){
				result.enable_validations=true;
			}
			if (result) {
				if(result.authDetails){
					Session.close()
					window.location.href='/sign/in'
				}
				return result;
			}
		}catch(e) {
			//console.log(e)
			result['error'] = 'Error del servidor contacte al administrador'
			return result
		}
		return null
	}
	encodeQueryStringFromArray(name, params, index) {
		const keys = Object.keys(params)
		return keys.length
		? keys
		.map(key => name + '[' + index + '][' + encodeURIComponent(key) + ']='
		+ encodeURIComponent(params[key]))
		.join("&")
		: ""
	}
	encodeQueryString(filters){
		var url = '';
		Object.keys(filters).forEach(function(key) {
			if (Array.isArray(filters[key])) {
				filters[key].map(function (item, index) {
					if(item!==null){
						url +=key+'='+item+'&'
					}
					return true
					//url += this.encodeQueryStringFromArray(key, item, index) + '&'
				},this);
			}
			else {
				if (filters[key]) {
					url += `${key}=${filters[key]}&`
				}
			}
		},this)
		return url.substring(0, url.length-1);
	}
	fromData(data){
		let body = '';
		if (typeof data['form-data'] === 'boolean'){
			//this.headers.headers['Content-Type']='multipart/form-data'
			body = new FormData()
			for(var name in data) {
				if(name!=='form-data'){
				    body.append(name, data[name])
				}
			}
		}else{
			body = JSON.stringify(data)
		}
		return body;
	}
}
export default new Fetch()