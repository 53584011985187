import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ModalGeneral = (props) => {
  const {
    buttonLabel,
    className,
    run,
    size,title
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  useEffect(() => {
    if (run!=null) {
  	  setModal(run);
    }
  },[props.run]);
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} size={size} className={className}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
        	{props.children}
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ModalGeneral;