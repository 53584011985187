
 class FormProcessing {
	async toObject(dataForm){
		var obj={}
		await Promise.all(
			Object.keys(dataForm).map( async (index) => {
				var i=dataForm[index]
				if( 
					i.localName === 'select' ||
					i.localName === 'input' ||
					i.localName === 'textarea'
				){
					
					if(i.type === 'file' && typeof i.files[0] !== 'undefined'){
						var name = i.files[0].name
						var promise = await  this.getBase64(i.files[0]);
						//var promise = await  this.getBase64(i);
						obj[i.name] = {
							file: promise.result,
							name,
						}
					}else{
						var valueField=i.value==='' ? null : i.value;
						if (i.type !== 'checkbox' && i.type !== 'radio' && i.name!=='') {
							if(i.name.match(/^.*\[\].*$/)){
								if(typeof obj[i.name] === 'undefined'){
									obj[i.name] = []
								}
								obj[i.name].push(valueField)
							} else {
								obj[i.name] = valueField
							}
						}
						if (i.type === 'checkbox'){
							if(i.checked === true){
								obj[i.name] = valueField
							}
						}
						if (i.type === 'radio'){
							if(i.checked === true){
								obj[i.name] = valueField
							}
						}
						
					}
				}
				return null
			})
		)
		//Si es form-data Reorganizo los array que vengan el from
		/*if(typeof obj['form-data'] !== 'undefined'){
			Object.keys(obj).map((name) => {
				if(typeof obj[name] === 'object'){
					let aux = obj[name]
					var n = name
					if(n.match(/^.*\[\].*$/)){
						delete obj[name]
						Object.keys(aux).map((index) => {
							let i = n.replace("[]", '['+index+']');
							obj[i]=aux[index]
							return null
						})
					}
				}
				return null
			})
		}*/
		return obj
	}
	validate(dataForm,validations){
		Object.keys(dataForm).map((index) => {
			var i = dataForm[index]
			var parent = i.parentNode;
			if(typeof parent === 'object' &&
				(
					i.localName === 'select' ||
					i.localName === 'input' ||
					i.localName === 'textarea'
				)
			){
				var feed = parent.getElementsByClassName('invalid-feedback');
				var search = (i.name in validations)
				i.className = i.className.replace("is-invalid", "")
				parent.className = parent.className.replace("form-check-invalid", "")
				if(feed.length > 0){
					feed[0].remove()
				}
				if(search){
					var wrapper = document.createElement('div');
					//console.log(validations[i.name])
					wrapper.innerHTML = validations[i.name][0].replace(
						'Recupera tu contraseña ahora',
						'<a href="/enviar/mail"><small>Recupera tu contraseña ahora</small></a>'
					)
					wrapper.className = 'invalid-feedback'
					if(parent.classList.contains("form-check-inline")){
						parent.classList.add("form-check-invalid") 
					}
					parent.appendChild(wrapper)
					i.className = 'form-control is-invalid'

				}
			}
			return true
		})
	}
	/*async getBase64(file, onLoadCallback) {
		return await new Promise( async function(resolve, reject) {
			var reader = new FileReader();
			reader.onload = function() {
				resolve(reader.result); 
			};
			reader.onloadstart = function(data) {
				if (data.lengthComputable) {
					//console.log('total-'+data.total)
					//console.log('Incio-'+data.loaded)
				}
			}
			reader.onprogress = function(data) {
				if (data.lengthComputable) {
					//console.log('cargando-'+data.loaded)
					var progress = parseInt( ((data.loaded / data.total) * 100), 10 );
					//console.log('prosentaje-'+progress+'%')
				}
			}
			reader.onloadend = async function(data) {
				if (data.lengthComputable) {
					//console.log('Fin-'+data.loaded)
					var progress = parseInt( ((data.loaded / data.total) * 100), 10 );
					//console.log('prosentaje-'+progress+'%')
				}
			}
			reader.onerror = reject;
			reader.readAsDataURL(file);
		});
	}*/
	async getBase64(event) {
		return await new Promise((resolve, reject) => {
		    const width = 500;
		    //const height = 300;
		    const fileName = event.name;
		    const reader = new FileReader();
		    reader.readAsDataURL(event);
		    reader.onload = event => {
		        const imgen = new Image();
		        imgen.src = event.target.result;
		        //console.log(event.target.result)
		        imgen.onload = () => {
	                const elem = document.createElement('canvas');
	                const scaleFactor = width / imgen.width;
					elem.width = width;
					elem.height = imgen.height * scaleFactor;
	                // elem.width = width;
	                // elem.height = height;
	                const ctx = elem.getContext('2d');
	                // img.width and img.height will contain the original dimensions
	                ctx.drawImage(imgen, 0, 0, width, imgen.height * scaleFactor);
	                //console.log('Render imagen')
	                ctx.canvas.toBlob((blob) => {
	                    const file = new File([blob], fileName, {
	                        type: 'image/jpeg',
	                        lastModified: Date.now()
	                    });

	                    var reader = new FileReader();
    					reader.readAsDataURL(file);
    					reader.onload = function () {
					        resolve(reader)
					    };
	                    
	                }, 'image/jpeg', 1);
	            }
	            reader.onerror = error => alert(error);
		    };

	    },event);
	}
	getTypeUsers(find=null){
		var list=[
			{ value: '1', label: 'Super Administrador' },
			{ value: '2', label: 'Administrador' },
			{ value: '3', label: 'Usuario' },
		]
		var resul=null;
		if(find!==null){
			resul=list.filter(function(obj) {
				if(obj.value===find+''){ return obj }else{ return false}
			},find);
			list = resul.length>0 ? resul[0] : '';
		}
		return list
	}
	getStatusUsers(find=null){
		var list=[
			{ value: '1', label: 'Habilitado' },
			{ value: '0', label: 'Deshabilitado' },
		]
		var resul=null;
		if(find!==null){
			resul=list.filter(function(obj) {
				if(obj.value===find+''){ return true }else{ return false}
			},find);
			list = resul.length>0 ? resul[0] : '';
		}
		return list
	}
	searchSelectOption(list='', find=null){
		var resul=null;
		if(find !== null &&  list !== ''){
			resul=list.filter(function(obj) {
				if(obj.value===find){ return true }else{ return false}
			},find);
			list = resul.length>0 ? resul[0] : '';
		}else{
			list=null
		}
		return list
	}
	
}
export default new FormProcessing()
