//import  Api from './Api'
import UserImg from '../Layout/Assets/user.png';

class Session {
	open = (response) => {
		if(response.avatar_url===null){
			response.avatar_url=UserImg
		}
		localStorage.setItem('token', response.token)
		localStorage.setItem('type_profile', response.type_profile)
		localStorage.setItem('status_user', response.status)
		localStorage.setItem('username', response.username)
		localStorage.setItem('full_name', response.full_name)
		localStorage.setItem('name',  response.name!=null ? response.name:"")
		localStorage.setItem('surname',  response.surname!=null ? response.surname:"")
		localStorage.setItem("fecha_Nacimiento", response.fechaNacimiento!=null ? response.fechaNacimiento:"");
		localStorage.setItem("rut", response.rut!=null ? response.rut:"");
		localStorage.setItem('avatar', response.avatar_url)
	}
	edit = (response) => {
		if(response.avatar_url===null){
			response.avatar_url=UserImg
		}
		//localStorage.setItem('type_profile', response.type_profile)
		localStorage.setItem('status_user', response.status)
		localStorage.setItem('username', response.username)
		localStorage.setItem('full_name', response.full_name)
		localStorage.setItem('avatar', response.avatar_url)
	}
	isAuth= () => {
		let isAuth = ("token" in localStorage)
		return isAuth
		 
	}
	getUserName = () => {
		return localStorage.getItem("username")
	}
	getTokenUser = () => {
		return localStorage.getItem("token")
	}
	getStatusUser = () => {
		return localStorage.getItem("status_user")
	}
	getTypeProfileUser = () => {
		return localStorage.getItem("type_profile")
	}
	getTypeProfileStrignUser = () => {
		var profile='';
		switch (localStorage.getItem("type_profile")) {
			case "0":
				profile = 'Juez'
			case "1":
				profile = 'Super Administrador'
				break;
			default:
				profile = 'sin perfil'
		}
		return profile;
	}
	isAdmin= () => {
		let isAdmin = ("type_profile" in localStorage && localStorage.getItem("type_profile")=="1");
		return isAdmin;
		 
	}
	getInfoUser = () => {
		return {
			avatar:localStorage.getItem("avatar"),
			username:localStorage.getItem("username"),
			full_name:localStorage.getItem("full_name")
		}
	}
	close = () => {
		localStorage.clear()		
	}
}
export default new Session()