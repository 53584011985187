import React, { Component, useEffect, useState } from 'react';
import { Col, Row, Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';
import Api from '../Services/Api';
import FormProcessing from '../Services/FormProcessing';


const FormProvaider = (props) => {
	const { provaider, loadList } = props
	const [data, setData] = useState({});
	const [form, setForm] = useState({});

	useEffect(() => {
		if(provaider!=null){
			setData(provaider)
			setForm(provaider)
		}
	},[props.provaider]);
	const selectActive = { selected: 'none'}

	const handleOnChnage = async (e,info)  => {
		if(e.target && e.target.type === 'file' && typeof e.target.files[0] !== 'undefined'){
			const name = e.target.files[0].name
			const promise = await  FormProcessing.getBase64(e.target.files[0]);
			setForm({
				...data,
				...form,
				logo_provider:{
					file: promise.result,
					name,
				},
			});
		}else{
			const name =  e.target ? e.target.name  : e;
			const value =  e.target ?
							 e.target.value  : info.value ? 
							 	info.value : info;
			setForm({
				...data,
				...form,
				[name]:value,
			});
		}
	}
	const handleSubmit = async (e)  => {
		e.preventDefault();
		let response = {};
		if (form.id) {
			response = await Api.adminProvaidersUpdate(form.id,form);
			alert('Actualizado de forma exitosa!');
		}else{
			response = await Api.adminProvaidersCreate(form);
			alert('Creado de forma exitosa!');
		}
		loadList()
	}
	return(
		<Form onSubmit={ (e) => handleSubmit(e) }>
			<Row form>
				<Col md={6}>
					<FormGroup>
						<Label for="provaiderName">Nombre</Label>
						<Input required type="text" id="provaiderName" name="name" placeholder="nombre del porveedor.." defaultValue={data.name} onChange={(e) => handleOnChnage(e)}/>

					</FormGroup>
				</Col>
				<Col md={6}>
					<FormGroup>
						<Label for="provaiderSlug">Slug</Label>
						<Input required type="text" id="provaiderSlug" name="slung" placeholder="slug del porveedor.." defaultValue={data.slug} onChange={(e) => handleOnChnage(e)} disabled/>
					</FormGroup>
				</Col>
			</Row>
			<FormGroup>
				<Label for="provaiderRegiones">Regiones</Label>
				<Input required type="select" id="provaiderRegiones" name="region_id" value={form.region_id} onChange={(e) => handleOnChnage(e)}>
					<option value=''>Selecciona la región</option>
					{data.selects ? data.selects.regions.map(function(elem, index) {
						return(
							<option value={elem.id} key={`regions-${index}`}>
							{elem.name}
							</option>
						);
					}) : <option>Sin region</option>}
				</Input>
			</FormGroup>
			<FormGroup>
				<Row form>
					<Col md={6}>
						<Label for="providersFile">Foto</Label>
						<FormGroup>
							<Input type="file"  id="providersFile" name="logo_provider" onChange={(e) => handleOnChnage(e)} />
							<FormText color="muted">
								Recuerda subir las fotos con las medidas necesarias.
							</FormText>
						</FormGroup>
					</Col>
					<Col md={6}>
						{
							!form.logo_provider && data.logo_url  ?
							<img src="img-fluid" src={data.logo_url} height="80"/> : 
							(form.logo_provider ? <img src="img-fluid" src={form.logo_provider.file} height="80"/> : '')
						}
					</Col>
				</Row>
			</FormGroup>
			<FormGroup>
				<Label for="provaiderDescription">Descripción(Acepta tags html)</Label>
				<Input type="textarea" id="provaiderDescription" name="description" defaultValue={data.description} onChange={(e) => handleOnChnage(e)} rows='8' cols='50'/>
			</FormGroup>
			<Button>{data.id ? 'Actualizar' : 'Crear'}</Button>
		</Form>
	);
}
export default  FormProvaider;