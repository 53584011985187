import  React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import RestaurantList  from './RestaurantList';
import View404 from '../Layout/View404';
class RouteVoteHere extends Component {	
	render(){
		return (
			<Switch>
				<Route exact path={["/vota-aca","/"]} component={RestaurantList}/>
				<Route exact path="/restoranes/zona/:zona" render={(props) => <RestaurantList {...props}/>}/>
				<Route component={View404}/>
			</Switch>
		);
	}
}
export default RouteVoteHere;