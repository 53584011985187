import  React, { Component } from 'react';
import { Spinner } from 'reactstrap';
import { Link } from "react-router-dom";
import SweetAlert from 'sweetalert2-react';
import brand from '../Layout/Assets/logo_nav.png'

import  Api from '../Services/Api'
import Session from '../Services/Session';
import FormProcessing from '../Services/FormProcessing'
import { GetForCountry }from '../Services/Helpers';

class SignUp extends Component {	
	constructor(props) {
		super(props)
		this.state = {
			loading:false,
			error:'',
			showAlert:false,
			success:'',
			redirect:false,
		}
		this.form = React.createRef()
		this.handleSubmit = this.handleSubmit.bind(this)
	}
	async handleSubmit(event){
		event.preventDefault();
        const urlParams = new URLSearchParams(window.location.search);
        const returnUrl = urlParams.get('return_url');
		var userAgent = navigator.userAgent || navigator.vendor || window.opera;
		this.setState({
			loading: true
		});
		let data = await FormProcessing.toObject(this.form.current)
		data.user_agent=userAgent;
        data.return_url = returnUrl;
		if (data.new_password!==data.password_confirmation) {
			alert('Las contraseñas deben ser iguales')
			this.setState({
				loading: false,
			});
			return null
		}
		let validate = []
		let response = await Api.signUp(data);
		if(response.enable_validations===true){
			validate = response
			console.log(response);
		} 
		FormProcessing.validate(this.form.current,validate)
		if(response.error){
			this.setState({
				loading: false,
				error:response.error
			});
		}else if (response.token){
			//Session.open(response)
			this.setState({
				loading: false,
				showAlert:true,
				success:response.success
			});

			localStorage.setItem("fecha_Nacimiento","");
			localStorage.setItem("rut","");
			localStorage.setItem("name","");
			localStorage.setItem("surname","");
            // if (returnUrl) {
            //     window.location.href=returnUrl;
            //     return;
            // }
            
		}else{
			this.setState({
				loading: false,
			});
		}
	}
	render(){
		let {loading, showAlert, success, redirect} = this.state
		if(redirect){
			window.location.href='/vota-aca';
		}
		return (
			<>
				<SweetAlert
					show={showAlert}
					title="¡Buenísimo!"
					type="success"
					text={success}
					onConfirm={() => this.setState({ redirect: true })}
					confirmButtonText="Ir a votar"
				/>
				<div className="sign-in container viewport-container h-100 d-flex align-items-center justify-content-center">
					<div className="col-10 col-sm-10 col-md-6 col-lg-3 px-2">
						<p className="text-center">
							<img src={GetForCountry().brand} alt="sdad" className="brand"/>
						</p>
						<form onSubmit={(e)=>this.handleSubmit(e)} ref={this.form}>
							<p className="text-center">¡Regístrate para poder votar!</p>
							<div className="form-group">

								<input 
									type="email"
									className="form-control custom-input"
									id="new_email"
									name="new_email"
									aria-describedby="emailHelp"
									placeholder="Email"
									required
								/>
							</div>
							<div className="form-group">
								<input 
									type="password"
									className="form-control custom-input"
									id="new_password"
									name="new_password"
									aria-describedby="emailHelp"
									placeholder="Contraseña"
									
								/>
							</div>
							<div className="form-group">
								<input 
									type="password"
									className="custom-input form-control"
									aria-describedby="passwordConfirmation"
									placeholder="Confirmar Contraseña"
									name="password_confirmation"
									id="password_confirmation"
									required
								/>
							</div>
							<button
								type="submit"
								className="btn btn-block py-3 custom-btn-red custom-btn"
								disabled={loading ? true : false}
							>
								{ 
									loading === false ? 'Registrarse' :
									<span>
									<Spinner style={{ 
										width: '1rem',
										height: '1rem',
										top: '-4px',
										position: 'relative'
									}}/> Registrando..
									</span>

								}
							</button>
							<div className="form-group text-center py-2">
								<Link to="/sign/in" className="text-dark">
									¿Ya te haz registrado? ¡Inicia sesión!
								</Link>
							</div>
							<br/>
							<div className="form-group text-center">
								<Link to="/enviar/mail" className="text-dark">
									Olvidé mi Contraseña
								</Link>
								<br/>
								<br/>
								<p>
									<small>
									Al registrarme acepto las políticas de privacidad
									<br/>
									<u>
										<a href="#politicas" className="text-dark">
											política de privacidad.
										</a>
									</u>
									</small>
								</p>
							</div>
						</form>
					</div>
				</div>
			</>
		);
	}
}
export default SignUp;