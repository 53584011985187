
import React from 'react';
import  { ReactComponent as BurgerSvg }  from '../../Layout/Assets/icons/burger.svg';
import  { ReactComponent as IceSvg }  from '../../Layout/Assets/icons/ice-cream.svg';
import  { ReactComponent as PastrySvg }  from '../../Layout/Assets/icons/pastry.svg';
import  { ReactComponent as SalverSvg }  from '../../Layout/Assets/icons/salver.svg';
import  { ReactComponent as SandwichSvg }  from '../../Layout/Assets/icons/sandwich.svg';
import  { ReactComponent as SushiSvg }  from '../../Layout/Assets/icons/sushi.svg';
import  { ReactComponent as PizzaSvg }  from '../../Layout/Assets/icons/pizza.svg';
import  { ReactComponent as BeerSvg }  from '../../Layout/Assets/icons/beer.svg';

import  { ReactComponent as FelchasSvg }  from '../../Layout/Assets/icons/flechas.svg';
import  { ReactComponent as SettingSvg }  from '../../Layout/Assets/icons/settings.svg';
import  { ReactComponent as ListaSvg }  from '../../Layout/Assets/icons/listaof.svg';
import  { ReactComponent as CuadriculaSvg }  from '../../Layout/Assets/icons/cuadriculaof.svg';
import  { ReactComponent as EstrellaBronceSvg }  from '../../Layout/Assets/icons/estrellabronce.svg';
import  { ReactComponent as EstrellaOroSvg }  from '../../Layout/Assets/icons/estrellaoro.svg';
import  { ReactComponent as EstrellaPlataSvg }  from '../../Layout/Assets/icons/estrellaplata.svg';

const Icons = {
  BurgerSvg,
  IceSvg,
  PastrySvg,
  SalverSvg,
  SandwichSvg,
  SushiSvg,
  PizzaSvg,
  BeerSvg,

  FelchasSvg,
  SettingSvg,
  ListaSvg,
  CuadriculaSvg,
  EstrellaBronceSvg,
  EstrellaOroSvg,
  EstrellaPlataSvg
};

export default Icons;