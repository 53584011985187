import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Session from '../../Services/Session';
import  { ReactComponent as Burguer } from  '../Assets/burguer-01.svg'
import  { ReactComponent as Pdf } from  '../Assets/pdf-01.svg'
import  { ReactComponent as Juez } from  '../Assets/juez-01.svg'
import  { ReactComponent as UserIco } from  '../Assets/usuario-01.svg'
import  { ReactComponent as Start } from  '../Assets/boton_estrella-01.svg'
import  { ReactComponent as Pizza } from  '../Assets/pizza.svg'
import  { ReactComponent as Sushi } from  '../Assets/sushi_1.svg'
import  { ReactComponent as Shop } from  '../Assets/shop.svg'

import { GetForCountry }from '../../Services/Helpers';


export default class NavBottom extends Component {
	render() {
		return (
			//Solo movil d-block d-lg-none d-xl-none
			<footer className="mt-5 d-md-none d-lg-none d-xl-none container fixed-bottom bg-white px-2 bar-footer border-top">
				<div className="d-flex justify-content-between mb-1 pt-1 text-center">
					<div className="pt-3 pb-3 pb-0 px-0 col">
						<Link className="" to="/vota-aca">
							{/*<i className="fas fa-concierge-bell fa-2x text-dark"/>*/}
							{/*<Burguer height="35px"/>*/}
							<Shop height="43px" style={{
								marginTop: '-5px',
								marginBottom: '-3px',
							}}/>
							<br/>
							<small className="text-dark">
								Participantes
							</small>
						</Link>
					</div>
					{/*<div className="pt-3 pb-3 pb-0 px-0 col">
						<Link className="" to="/">
							<i className="far fa-file-pdf fa-2x text-dark"/>
							<Pdf height="35px"/>
							<br/>
							<small className="text-dark">
								PDF
							</small>
						</Link>
					</div>*/}
					<div className="pt-3 pb-3 pb-0 px-0 col">
						<a className="" href={GetForCountry().blogLink}>
							<img src="https://thetop.cl/wp-content/uploads/2020/03/blog.svg" height="35px"/>
							<br/>
							<small className="text-dark">
								Blog
							</small>
						</a>
					</div>
					<div className="pt-3 pb-3 pb-0 px-0 col start-menu">
						<Link className="" to="/">
							{/*<i className="far fa-star fa-2x start-vote"/>*/}
							<Start height="35px" className="start-vote"/>
							<small className="text-dark">
								Vota Acá
							</small>
						</Link>
					</div>
					<div className="pt-3 pb-3 pb-0 px-0 col">
						<Link className="" to="/juez">
							{/*<i className="fas fa-gavel fa-2x text-dark"/>*/}
							<Juez height="35px"/>
							<br/>
							<small className="text-dark">
								Top Juez
							</small>
						</Link>
					</div>
					<div className="pt-3 pb-3 pb-0 px-0 col">
						<Link className="" to={"/mi/perfil/"+Session.getUserName()}>
							{/*<i className="fas fa-user fa-2x text-dark"/>*/}
							<UserIco height="35px"/>
							<br/>
							<small className="text-dark">
								Mi perfil
							</small>
						</Link>
					</div>
				</div>
			</footer>
		);
	}
}