import React, { useEffect } from 'react';
import { Panel, Drawer, Button, TagGroup, Tag } from 'rsuite';
import * as QueryString from 'query-string';
import { useHistory } from 'react-router-dom';

import Icons from '../Icons';
import FilterOrderByEvents from './FilterOrderByEvents'
import Api from '../../Services/Api'
import BreadCrumbsClassic from '../BreadCrumbs/BreadCrumbsClassic'

const FilterEvents = (props) => {
	return(
		<div
      className={`row align-items-center justify-content-md-end border-bottom ${props.addClass}`}
      style={props.addStyle ? props.addStyle : {}}
    >
      <div className="col-12">
        <BreadCrumbsClassic items={props.breadCrumbs}/>
      </div>
      <div className="col-3">
        Vista:{' '}
        {/* <Icons.ListaSvg/>{' '} */}
        <Icons.CuadriculaSvg/>
      </div>
      <div className="col-6 border-left border-right py-2">
        <FilterOrderByEvents input classNameInput="filters-select-order-by" />
      </div>
      <div className="col-3">
        <MenuDrawer/>
      </div>
    </div>
	);
}

export default FilterEvents;

const MenuDrawer = (props) =>{
  const history = useHistory();
	const query = QueryString.parse(history.location.search);

  const [size, setSize] = React.useState('xs');
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState({
    events:[],
    regions:[],
    events_year:[],
  });
  const [openPanel, setOpenPanel] = React.useState(null);
  const [placement, setPlacement] = React.useState();

  useEffect(()=>{
    const run = async () => {
      const data = await Api.filters();
      if (data.data) {
        setOptions(data.data)
      }
    }
    run()
  },[])

  const handleOpen = (key) => {
    setOpen(true);
    setPlacement(key);
  };

  return(
    <>
    <Button onClick={() => handleOpen('left')} className="bg-white">
      <Icons.SettingSvg/>{' '}
      Filtrar
    </Button>
    
    <Drawer
      size={size}
      placement={placement}
      open={open}
      onClose={() => setOpen(false)}
      style={{
        width:'20rem',
        // top:'5.5vh',
        // maxHeight:'94.5vh',
        zIndex:1051,
      }}
    > 
      <Drawer.Header>
        <Drawer.Title className="text-center">
          <h5>Filtros</h5>
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body className="px-0">
        <p className="px-3 mb-3">
          Filtro seleccionado
        </p>
        <div className="d-block px-3">
          <TagGroup>
            {
              query ? Object.keys(query).map((key1)=>{
                return(
                  <>
                    { options[key1] && options[key1].length > 0  ? 
                      <Tag
                        closable 
                        onClose={(e) => {
                          e.preventDefault()
                          delete query[key1];
                          history.push({
                            pathname: history.location.pathname,
                            search:QueryString.stringify(query),
                            //state: { [item.type]: item1.slug }
                          })
                        }}
                      >
                        {options[key1].find(x => x.slug == query[key1]).label}
                      </Tag> : 'none' 
                    }
                  </>
                )
              }):null
            }
          </TagGroup>
        </div>
        <hr className="w-100"/>
        <ul className="list-group list-group-flush">
          {
            [
              {name:'Categoría | Eventos',options:options.events, type:'events'},
              {name:'Regíon | Ciudad',options:options.regions, type:'regions'},
              {name:'Año',options:options.events_year, type:'events_year'},
            ].map((item,i)=>{
              return(
                <>
                <a href="#" className="list-group-item d-flex justify-content-between align-items-center py-4 border-bottom-1 text-dark" onClick={() => setOpenPanel(openPanel===i ? null : i)}>
                  {item.name}
                  <span><i className="fas fa-chevron-right"></i></span>
                </a>
                <Panel collapsible  expanded={openPanel===i}>
                {
                  item.options.map((item1,i1)=>{
                    return(
                      <a href="#" className={"text-dark py-2 d-block"}
                        onClick={(e) => {
                          e.preventDefault()
                          query[item.type] = item1.slug;
                          history.push({
                            pathname: history.location.pathname,
                            search:QueryString.stringify(query),
                            state: { [item.type]: item1.slug }
                          })
                        }}
                      >
                        {item1.label}
                      </a>
                    )
                  })
                }
                </Panel>
                </>
              )
            })
          }
        </ul>
      </Drawer.Body>
    </Drawer>
    </>
  );
}

const MenuDrawerItem = (props) => {
  return(
    <li className="list-group-item d-flex justify-content-between align-items-center py-4 border-bottom-1">
      {props.name}
      <span><i className="fas fa-chevron-right"></i></span>
    </li>
  );
}
