import React, { useEffect, useState } from "react";
import Api from "../Services/Api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function Terminos() {
  const [data, setData] = useState({});
  const { id } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      // let id_Encriptado=await Api.encriptacionId(2)
      //   console.log(id_Encriptado,"Por aca");
      try {
        const res = await Api.getTerminosCondiciones(id);
        setData(res);
      } catch (error) {
        console.error("Error al obtener datos:", error);
      }
    };

    fetchData();
    return () => {};
  }, [id]);

  return (
    <div style={{ marginTop: "80px" }}>
      <h3
        style={{
          fontWeight: "bold",
          fontSize: "25px",
          textAlign: "center",
          textDecoration: "underline",
        }}
      >
        Terminos y Condiciones
      </h3>
      <div
        style={{
          display: "flex",
          gap: "20%",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          marginTop: "40px",
        }}
      >
        <div>
          <div style={{ display: "flex", gap: "15px" }}>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                paddingTop: "5px",
                width: "140px",
              }}
            >
              Usuario :
            </p>
            <p style={{ fontSize: "15px", paddingTop: "0px" }}>
              {data.username}
            </p>
          </div>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              gap: "15px",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                paddingTop: "4px",
                width: "140px",
              }}
            >
              Email :
            </p>
            <p style={{ fontSize: "15px" }}>{data.email}</p>
          </div>
          {data.name && (
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                gap: "15px",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontWeight: "bold",
                  fontSize: "18px",
                  width: "140px",
                }}
              >
                Nombre :
              </p>
              <p style={{ margin: 0, fontSize: "15px" }}>{data.name}</p>
            </div>
          )}
          {data.surname && (
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                gap: "15px",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontWeight: "bold",
                  fontSize: "18px",
                  width: "140px",
                }}
              >
                Apellido :
              </p>
              <p style={{ margin: 0, fontSize: "15px" }}>{data.surname}</p>
            </div>
          )}

          {data.idVotacion && (
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                gap: "15px",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontWeight: "bold",
                  fontSize: "18px",
                  width: "140px",
                }}
              >
                ID Aceptacion: {data.idVotacion}
              </p>
              {/* <p style={{ margin: 0, fontSize: "15px",paddingLeft:"5px" }}></p> */}
            </div>
          )}
        </div>
        <div style={{ width: "110px" }}>
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/6/6b/Check_green_circle.svg"
            alt="check"
            style={{ width: "100%" }}
          />
        </div>
      </div>
      <div
        style={{
          fontWeight: "bold",
          fontSize: "20px",
          paddingTop: "35px",
          textAlign: "center",
        }}
      >
        Acepto los términos y condiciones el {data.creacion}
      </div>
    </div>
  );
}

export default Terminos;
