import React, {Component} from 'react';
import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { GetForCountry }from '../../Services/Helpers';

export default class NavTop extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
        <Navbar expand="md" className="bg-black p-0 px-2 nav-top d-none d-md-block">
        	<div className="container px-0">
			<Nav className="mr-auto" navbar>
				<NavItem>
					<NavLink className="p-1 text-white" href="mailto:contacto@thetop.cl">
						<i className="fa fa-envelope mr-2 fa-red" aria-hidden="true"/> 
						{GetForCountry().emailContact}
					</NavLink>
				</NavItem>
			</Nav>
			<Nav className="ml-auto" navbar>
				<NavItem className="border-left-1 pr-md-3">
					<Link to={"/vota-aca"} className="p-1 text-white nav-link">
						<i className="fa fa-archive mr-2 fa-red" aria-hidden="true"/>
						VOTA ACÁ
					</Link>
				</NavItem>
				<NavItem className=" pl-md-3">
					<NavLink className="p-1 text-white" href={GetForCountry().facebook}>
						<i className="fa fa-facebook mr-2" aria-hidden="true"/> 
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink className="p-1 text-white" href={GetForCountry().instagram}>
						<i className="fa fa-instagram mr-2" aria-hidden="true"/> 
					</NavLink>
				</NavItem>
			</Nav>
			</div>
        </Navbar>
    );
  }
}