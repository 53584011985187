import React, { useEffect, useState } from 'react';
import { Dropdown, Rate, Drawer, Button, TagGroup, Tag } from 'rsuite'
import { useParams } from "react-router-dom";
import * as QueryString from 'query-string';
import { useHistory, Link } from 'react-router-dom';

import Api from '../Services/Api';
import Icons from '../Components/Icons';
import WidgetCategory from '../Components/Widgets/WidgetCategory';
import FilterEvents from '../Components/Filters/FilterEvents';
import MainAutoComplete from '../Components/Searchs/MainAutoComplete'
import { Brand } from '../Layout/Users/Components/NavMain';
import {GetNumericFloat} from '../Services/Helpers';


const EventsPublic = (props) => {
  const history = useHistory();
  let { id } = useParams();
  let [ list, setList ] = useState([]);
  let [ event, setEvent ] = useState({});

  useEffect(()=>{
    const run = async () =>{
      let query = QueryString.parse(history.location.search);
      console.log(query)
      const response = await Api.getEventsListingR({
        ...query,
        id:id
      })
      setList(response.data)
      setEvent(response.event)
    }
    run()
  },[history.location.search,props,id]);

  return(<>
    <FilterEvents
      addClass="mb-3 pt-5 sticky-top shop-filters-sticky"
      breadCrumbs={[
        {link:'/reviews',name:'Reviews'},
        {link:`/evento/${id}`,name:id},
      ]}
    />
    <div className="row">
      <div className="col-12 pb-3 px-1">
        <MainAutoComplete placeholder="BUSCAR UN RESTAURANT/MARCA"/>
      </div>
      <div className="col-12">
        <h3 className="text-center">{event.name}</h3>
      </div>
      <div className="col-12 col-md-12">
        <div className="row">
          {
            list.length >0 ? list.map((item, i)=>{
              return(
                <div className="col-6 col-md-3 col-lg-2 mt-1 mb-0 px-1">
                  <Link to={`/proveedor/${item.restaurant_slug}`}>
                    <div className="card w-100 my-1">
                      <img alt="" className="card-img-top" src={item.logo_url_restaurant} style={{
                        maxHeight: "150px",
                        objectFit: "contain",
                        width: "auto",
                      }}/>
                      <div className="card-body">
                        <p className="small font-weight-bolder"
                            style={{
                            minHeight: "15px",
                            display: '-webkit-box',
                            overflow: 'hidden',
                            webkitLineClamp: '1',
                            webkitBoxOrient: 'vertical',
                          }}
                        >{item.restaurant_name}</p>
                        <Rate value={item.rating_final} allowHalf readOnly key={`r-n-${item.id}`} size="xs"/>{' '}
                        <small className="small rate-item">{GetNumericFloat(item.rating_final)}</small>
                        <hr className="row my-1"/>
                        {/* <strong className="small">Comentario</strong>
                        <p className="card-text small"
                          style={{
                            minHeight: "58px",
                            display: '-webkit-box',
                            overflow: 'hidden',
                            webkitLineClamp: '4',
                            webkitBoxOrient: 'vertical',
                          }}
                        >
                          {item.description}
                        </p> */}
                        <a href="#" className="btn btn-link p-0">ver más</a>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            }) : <div className="col-12"><h5 className="text-center py-5">Sin resultados</h5></div>
          }
        </div>
      </div>
      <div className="col-12 col-md-12 px-4 mt-4">
        <h4 className="mt-4 mt-md-0">Eventos
        </h4>
        <WidgetCategory elementClassName='col-6 col-md-4'/>
      <div/>
    </div>
    </div>
  </>);
};

export default EventsPublic;
